import React, {useEffect, useState} from 'react';
import { Layout, Button, Menu,message} from 'antd';

import logo from '../../assets/images/logo.svg';
import Sidebar from "../../Components/sidebar/Sidebar";
import './AppLayout.css';
import {Image} from "antd";
import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
import {selectNotification} from "../../redux/app/app.selectors";
import {useDispatch, useSelector} from "react-redux";
import {setNotification} from "../../redux/app/app.slice";
import Notification from "../../Components/notification/Notification";


const { Content, Header } = Layout;

const AppLayout = props => {

    const [collapsed, setCollapsed] = useState(false);
    const notification = useSelector(selectNotification);
    const dispatch = useDispatch()

    const [messageApi, contextHolder] = message.useMessage();




    const handlSidebarCollapse =()=>{
        setCollapsed(!collapsed)
      }
    return (
        <Layout style={{ minHeight: '100vh' }} className={"app-layout"}>
            {!props.hidesidebar && <LeftSideBar collapsed={collapsed} handlSidebarCollapse={handlSidebarCollapse}  hideClass={props.hideClass} classroom={props.classroom}/> }
            <Content className={"layout"}>
                <div className={"main-content"}>
                    {!props.hideHeader &&
                        <Header className="header">
                            <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']}>
                                <Menu.Item key="2">
                                    <Image
                                        width={140}
                                        src={logo}
                                        alt="Background"
                                        preview={false}
                                    />
                                </Menu.Item>
                            </Menu>
                        </Header>
                    }
                    <div className={""}>
                        {contextHolder}
                        <Notification  notification={notification} />
                        {props.children}
                    </div>
                </div>
            </Content>
        </Layout>
    )
}

export default AppLayout

