

const FileIcon = () => {

    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2221_7366)">
                <path
                    d="M9.33341 2.28316V5.03685C9.33341 5.41022 9.33341 5.5969 9.40608 5.73951C9.46999 5.86495 9.57198 5.96694 9.69742 6.03085C9.84003 6.10352 10.0267 6.10352 10.4001 6.10352H13.1538M13.3334 7.429V12.2368C13.3334 13.357 13.3334 13.917 13.1154 14.3448C12.9237 14.7212 12.6177 15.0271 12.2414 15.2189C11.8136 15.4368 11.2535 15.4368 10.1334 15.4368H5.86675C4.74664 15.4368 4.18659 15.4368 3.75877 15.2189C3.38244 15.0271 3.07648 14.7212 2.88473 14.3448C2.66675 13.917 2.66675 13.357 2.66675 12.2368V5.30352C2.66675 4.18341 2.66675 3.62336 2.88473 3.19553C3.07648 2.81921 3.38244 2.51325 3.75877 2.3215C4.18659 2.10352 4.74664 2.10352 5.86675 2.10352H8.00793C8.49711 2.10352 8.7417 2.10352 8.97188 2.15878C9.17595 2.20777 9.37104 2.28858 9.54998 2.39824C9.75182 2.52192 9.92477 2.69487 10.2707 3.04077L12.3962 5.16626C12.7421 5.51216 12.915 5.68511 13.0387 5.88695C13.1484 6.06589 13.2292 6.26098 13.2782 6.46505C13.3334 6.69523 13.3334 6.93982 13.3334 7.429Z"
                    stroke="#042A49" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2221_7366">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.769531)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default FileIcon;