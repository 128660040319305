
const MagicIcon = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.6408 3.63882L20.3608 2.35882C20.2483 2.24514 20.1143 2.15489 19.9667 2.0933C19.8191 2.03171 19.6607 2 19.5008 2C19.3408 2 19.1825 2.03171 19.0349 2.0933C18.8872 2.15489 18.7533 2.24514 18.6408 2.35882L2.36078 18.6388C2.24709 18.7513 2.15685 18.8853 2.09526 19.0329C2.03367 19.1805 2.00195 19.3389 2.00195 19.4988C2.00195 19.6588 2.03367 19.8171 2.09526 19.9648C2.15685 20.1124 2.24709 20.2463 2.36078 20.3588L3.64078 21.6388C3.7526 21.7537 3.88631 21.8451 4.03402 21.9074C4.18173 21.9698 4.34044 22.0019 4.50078 22.0019C4.66111 22.0019 4.81982 21.9698 4.96753 21.9074C5.11524 21.8451 5.24896 21.7537 5.36078 21.6388L21.6408 5.35882C21.7557 5.247 21.847 5.11329 21.9094 4.96558C21.9717 4.81787 22.0039 4.65916 22.0039 4.49882C22.0039 4.33849 21.9717 4.17978 21.9094 4.03207C21.847 3.88436 21.7557 3.75065 21.6408 3.63882Z"
                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.9961 7.00391L16.9961 10.0039" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M4.99609 6V10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.0039 13.9961V17.9961" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M10.0039 1.99609V3.99609" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M7 7.99609H3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.9961 16.0039H16.9961" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M11 3H9" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default  MagicIcon;