import teacherVector from "../../../assets/images/teacher-vector.svg";
import studentVector from "../../../assets/images/student-vector.svg";
import remediaLogo from "../../../assets/images/remedia-logo.png";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {InfoCircleOutlined} from "@ant-design/icons"
import { Link } from "react-router-dom";
import {string , object } from 'yup';
import { Button, Alert } from "antd";
import React from 'react';
import './choseRole.css';
import {useIntl} from "react-intl";


const ChoseRole = ({data , nextStep}) => {
const intl = useIntl();
    const handleSubmit = (values) =>{
        nextStep(values)
    }

    const validationSchema = object().shape({
      type: string()
        .oneOf(['student', 'teacher'], 'Please choose a role first')
        .required('Please choose a role first'),
    });

  return (
    <main>
      <img src={remediaLogo} alt="remedia logo" className="__remediaLogo" />
      <div className="container">
        <div className="choseRole __box">
          <h1 className="__title">
              {intl.formatMessage({id: "signUp.title"})}
          </h1>
          <span className="choseRole__subTitle __mixSubTitle">
            {intl.formatMessage({id: "signUp.select.role"})}
          </span>
          

          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur }) => (
            <>
                <ErrorMessage name="type">
                    {msg => (
                        // <div className="__errorMessage __errorField">
                        //     <InfoCircleOutlined style={{color: "red"}} /> {msg}
                        // </div>
                        <Alert message={msg} type="error" icon={<InfoCircleOutlined /> } showIcon className="__errorField"/>
                    )}
                </ErrorMessage>
                <Form className='choseRole__form'>
                    <div className="choseRole__role">
                        <div className="choseRole__role-student">
                        <Field
                            type="radio"
                            name="type"
                            id="student"
                            value="student"
                            checked={values.type === 'student'}
                        />
                        <label htmlFor="student">
                            <img src={studentVector} alt="student vector" />
                            {intl.formatMessage({id: "signUp.role.student"})}
                        </label>
                        </div>
                        <div className="choseRole__role-teacher">
                        <Field
                            type="radio"
                            name="type"
                            id="teacher"
                            value="teacher"
                            checked={values.type === 'teacher'}
                        />
                        <label htmlFor="teacher">
                            <img src={teacherVector} alt="teacher vector" />
                            {intl.formatMessage({id: "signUp.role.teacher"})}
                        </label>
                        </div>
                    </div>
                    <div className={"sign_up__btn__block"}>
                        <Button htmlType="submit" className="choseRole__continueBtn __authBtn">
                            {intl.formatMessage({id: "signUp.button.continue"})}
                        </Button>
                        <p>{intl.formatMessage({id: "signUp.link.already.have.account"})} <Link to="/sign-in">{intl.formatMessage({id: "signUp.link.signIn.here"})}</Link></p>
                    </div>

                </Form>
            </>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
};

export default ChoseRole;