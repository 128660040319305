import React, { useEffect, useState } from "react";
import style from "./assignmentCorrectionsPreview.module.css";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import * as Yup from "yup";

import BarCorrectionCount from "../barCorrectionCount";
import QuestionsItem from "../questionsItem";

import {useIntl} from "react-intl";

const AssignmentCorrectionsPreview = ({ assignments, isPreview = true }) => {
    const [initialValues, setInitialValues] = useState([]);
    const intl = useIntl();

    const randomNumber = Math.floor(Math.random() * 4) + 1
    const [AssignmentSchema, setAssignmentSchema] = useState(
        Yup.object().shape({})
    );

    const orderQuestions = ["A", "B", "C", "D"];

    const __handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(false);
    };

    const color ={
        multi: "#ffcf00",
        shortAnswer: "#000095",
        essay: "#662D91",
    }

    //type :
    useEffect(() => {
        const mappedQuestions = assignments?.questions.map((item) => ({
            ...item,
        }));

        /*   const AssignmentSchema = assignments?.questions.reduce((acc, question) => {
          acc[question.id] = question.answerMode == "multi" ? Yup.array().min : Yup.string().required("Answer is required");
          return acc;
        }, {}); */

        setAssignmentSchema(Yup.object().shape(AssignmentSchema));

        console.log("mappedQuestions",mappedQuestions);

        setInitialValues(mappedQuestions);
    }, [assignments]);

    return (
        <div className={style.container}>
            <h2 className={style.title}>{assignments?.title}</h2>
            <div className={style.containerCorrectionContent}>
                <div className={style.containerAssignment}>
                    <h5 className={style.introductionTitle}>{intl.formatMessage({id: "assignment.correction.introduction"})}</h5>
                    <p className={style.introduction}>{assignments?.introduction}</p>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={AssignmentSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={__handleSubmit}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <div className={style.listQuistionsContainer}>
                            {values?.map((question, indexQuestion) => (
                                <div
                                    className={style.questionItemContainer}
                                    key={"Questions+" + indexQuestion}
                                >
                                    <QuestionsItem
                                        question={question}
                                        indexQuestion={indexQuestion}
                                    />

                                    <div className={style.correctionsContainer} style={{ border: `1px solid ${color[question?.answerMode]}`, borderTop: ` 22px solid ${color[question?.answerMode]}` }}>
                                        <div className={style.correctionsContent}>
                                            {
                                                question?.correctAnswer ?
                                                    <p className={style.rubricName}>{intl.formatMessage({id: "assignment.correction.rubric.name"})}</p>
                                                    :
                                                    <>
                                                        <p className={style.correctionsTitle}>{intl.formatMessage({id: "assignment.correction.correctAnswer"})}</p>
                                                        <div className={style.optionsContainer}>
                                                            {question?.options?.map((item, index) => (
                                                                <div
                                                                    key={index}
                                                                    className={`${style.cercleAnwser} ${
                                                                        item?.isCorrect && style.cercleAnwserCorrect
                                                                    }`}
                                                                >
                                                                    {orderQuestions[index]}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>

                                            }
                                            {

                                                question?.correctAnswer && (
                                                    <>
                                                        <div className={style.justificationTitle}>
                                                            <span>Vocabulary usage</span>
                                                            <span className={style.justificationNumber}>
                                                                  {
                                                                      randomNumber
                                                                  }
                                                                / 4
                                                            </span>

                                                            <BarCorrectionCount length={4} correctLength={randomNumber}/>

                                                        </div>
                                                        <div className={style.justificationTitle}>
                                                            <span>Paraghraph Structure</span>
                                                            <span className={style.justificationNumber}>
                                                                  {
                                                                      randomNumber
                                                                  }
                                                                / 4
                                                            </span>

                                                            <BarCorrectionCount length={4} correctLength={randomNumber}/>

                                                        </div>
                                                        <div className={style.optionsContainer}>
                                                            <div className={style.correctionAnswer}>
                                                                {question?.correctAnswer}
                                                            </div>
                                                        </div>

                                                    </>
                                                )
                                            }

                                        </div>

                                        {question?.justification && (
                                            <div className={style.justificationContent}>
                                                <div className={style.justificationTitle}>
                                                    <span>{intl.formatMessage({id: "assignment.correction.justification"})}</span>
                                                    <span className={style.justificationNumber}>
                                                          {
                                                              question?.options?.filter(
                                                                  (item, index) =>
                                                                      item?.isCorrect &&
                                                                      question?.answer?.includes?.(index)
                                                              )?.length
                                                          }
                                                        / {question?.options?.length}
                                                    </span>

                                                    <BarCorrectionCount  length={4} correctLength={randomNumber} />

                                                </div>
                                                <div>
                                                    <div className={style.justificationAnswerContainer}>
                                                        <p>{question?.answerJustification}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {!isPreview && (
                                <div className={style.btnSubmitContainer}>
                                    <button
                                        type="submit"
                                        className={style.btnSubmit}
                                        onClick={handleSubmit}
                                    >
                                        {intl.formatMessage({id: "assignment.correction.button.submit"})}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AssignmentCorrectionsPreview;


