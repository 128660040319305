import { DoubleRightOutlined } from "@ant-design/icons";
import React from "react";
import style from "../assignmentCorrectionsPreview/assignmentCorrectionsPreview.module.css";
import { useIntl } from "react-intl";
import CustomCheckbox from "../customCheckbox";
const QuestionsItem = ({ question, indexQuestion, setFieldValue }) => {
    const isMultiple = question?.type === "MultiChoice";
    const isEssayMode = question?.type === "Essay";
    const isShortAnswer = question?.type === "ShortAnswer";
    const intl = useIntl();
    const bgColor = {
        MultiChoice: { background: "#FFCF00" },
        Essay: { background: "#000095" },
        ShortAnswer: { background: "#662D91" },
    };
    const url = process.env.REACT_APP_API_BASE_URL;
    const [openCollapse, setOpenCollapse] = React.useState(true);
    return (
        <div className={style.questionItemContant} style={bgColor[question?.type]}>
            <div className={style.header}>
                <div className={style.titleQuistion}>
                    <h6>{`${intl.formatMessage({
                        id: "assignment.preview.question.index",
                    })} ${indexQuestion + 1}`}</h6>
                    <span className={style.badgeType}>{question.type}</span>
                </div>
                <div>
                    <button onClick={() => setOpenCollapse(!openCollapse)}>
                        <DoubleRightOutlined
                            style={{
                                color: "#fff",
                                transform: openCollapse ? "rotate(90deg)" : "rotate(-90deg)",
                            }}
                        />
                    </button>
                </div>
            </div>
            <div
                className={`${style.contentMain} ${openCollapse ? "" : style.hide} `}
            >
                <div className={style.bodyQuestion}>
                    {question.image && (
                        <img
                            src={`${url}/uploads/images/${question.image.filePath}`}
                            alt="textContent"
                        />
                    )}
                    <p className={style.textContent}>{question.questionText}</p>
                </div>
                <div className={style.options}>
                    {isMultiple &&
                        question?.options.map((option, index) => (
                
                         <CustomCheckbox
                                key={option?.id}
                                label={option?.text}
                                value={option?.id}
                                isSelected={question?.studentAnswer?.includes(option?.id)}
                                onChange={(value) => {
                                    const updatedSelection = question?.studentAnswer?.includes(
                                        value
                                    )
                                        ? question?.studentAnswer?.filter((item) => item !== value) // Remove the value
                                        : [...(question?.studentAnswer || []), value]; // Add the value
                                    setFieldValue?.(`${indexQuestion}.studentAnswer`, updatedSelection);
                                }}
                            />            
                        ))}
                </div>
                {isShortAnswer && (
                    <textarea
                        className={style.textarea}
                        style={setFieldValue  ? {} : {pointerEvents: 'none'}}
                        rows={3}
                        name={`${indexQuestion}.answer`}
                        value={question?.answer}
                        onChange={(e) => {
                            setFieldValue?.(`${indexQuestion}.answer`, e.target.value);
                        }}
                    ></textarea>
                )}
                {isEssayMode && (
                    <div className={style.textareaContainer}>
            <textarea
                className={style.textareaV2}
                style={setFieldValue  ? {} : {pointerEvents: 'none'}}
                rows={5}
                name={`${indexQuestion}.answer`}
                value={question?.answer}
                onChange={(e) => {
                    setFieldValue?.(`${indexQuestion}.answer`, e.target.value);
                }}
            ></textarea>
                    </div>
                )}
                {question?.justification && isMultiple && (
                    <div className={style.textareaContainer}>
                        <label
                            htmlFor={"justification" + indexQuestion}
                            className={style.textareaLabel}
                        >
                            {intl.formatMessage({
                                id: "assignment.preview.question.label.justification",
                            })}
                        </label>
                        <textarea
                            className={style.textarea}
                            rows={5}
                            name={`${indexQuestion}.justification`}
                            id={"justification" + indexQuestion}
                            value={question?.answerJustification}
                            onChange={(e) => {
                                setFieldValue?.(
                                    `${indexQuestion}.justification`,
                                    e.target.value
                                );
                            }}
                        ></textarea>
                    </div>
                )}
            </div>
        </div>
    );
};
export default QuestionsItem;