import deleteYellowIcon from "../../assets/images/yellowDelete.svg";
import { Input, InputNumber } from "antd";
import "./criteriaScore.css"
import {useIntl} from "react-intl";

const { TextArea } = Input;


const CriteriaScore = ({ score, onScoreChange, onDeleteScore, error }) => {
    const intl = useIntl();
    return (
        <div className="criteriaScore">
            <div className="levelsBoxTitle">
                {intl.formatMessage({id: "rubric.create.form.field.criteria.score.level.title"})}
            </div>
            <div className="levelsBox">
                <div className="levelsBox-title">
                    <Input
                        className="criteriaLevel"
                        placeholder={intl.formatMessage({id: "rubric.create.form.field.criteria.score.level.placeholder"})}
                        value={score.title}
                        onChange={(e) => onScoreChange('title', e.target.value)}
                    />
                </div>
                <div className="levelsBox-point">
                    <InputNumber
                        min={0}
                        className="criteriaPoint"
                        placeholder={intl.formatMessage({id: "rubric.create.form.field.criteria.score.levelScore.placeholder"})}
                        name={score.point}
                        value={score.point}
                        onChange={(value) => onScoreChange('point', value)}
                    />
                    {error && <div className="errorMessage">{error.point}</div>}
                </div>
            </div>
            <TextArea
                className="description"
                placeholder={intl.formatMessage({id: "rubric.create.form.field.criteria.score.levelDescription.placeholder"})}
                value={score.description}
                onChange={(e) => onScoreChange('description', e.target.value)}
            />
            {error && <div className="errorMessage">{error.description}</div>}
            <button className="deleteCriteriaScore"  type="button" onClick={onDeleteScore}>
                <img src={deleteYellowIcon} width={15} alt="delete icon" />
                {intl.formatMessage({id: "rubric.create.form.field.criteria.button.delete"})}
            </button>
        </div>
    );
}

export default CriteriaScore
