

const UploadFileIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9014 20.2826L20.439 12.5645" stroke="#98A2B3" stroke-width="1.94118" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M15.5898 6.44598L7.42329 14.7794C7.05938 15.1435 6.85494 15.6371 6.85494 16.1519C6.85494 16.6666 7.05938 17.1602 7.42329 17.5243C7.78731 17.8882 8.28097 18.0926 8.7957 18.0926C9.31043 18.0926 9.80409 17.8882 10.1681 17.5243L18.3346 9.1908C19.0625 8.46275 19.4713 7.47544 19.4713 6.44598C19.4713 5.41652 19.0625 4.4292 18.3346 3.70115C17.6066 2.97332 16.6193 2.56445 15.5898 2.56445C14.5604 2.56445 13.573 2.97332 12.845 3.70115L4.6775 12.0337C3.58528 13.1259 2.97168 14.6072 2.97168 16.1519C2.97168 17.6965 3.58528 19.1778 4.6775 20.2701C5.76971 21.3623 7.25108 21.9759 8.7957 21.9759C10.3403 21.9759 11.8217 21.3623 12.9139 20.2701"
                stroke="#98A2B3" stroke-width="1.94118" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default UploadFileIcon;