import React, {useState} from 'react';
import { SubmissionCard } from '../../Components/submissionCard';
import styles from './courseworkStudentsSubmission.module.css';
import {SearchOutlined} from "@ant-design/icons";
import AppLayout from "../Layout/AppLayout";
import Filter from "../../Components/filter/Filter";
import {useIntl} from "react-intl";

const CourseworkStudentsSubmission = () => {

    const [selectedFilterItems, setSelectedFilterItems] = useState([]);
    const intl = useIntl();

    const submissions = [
        { id: 1, student: "Floyd Miles", date: "7/15/24 3:04 PM", isScored: false, hasCorrected: true },
        { id: 2, student: "Floyd Miles", date: "7/15/24 3:04 PM", isScored: false, hasCorrected: true },
        { id: 3, student: "Kathryn Murphy", date: "7/15/24 3:04 PM", isScored: true, hasCorrected: false },
        { id: 4, student: "Floyd Miles", date: "7/15/24 3:04 PM", isScored: false, hasCorrected: true },
        { id: 5, student: "Floyd Miles", date: "7/15/24 3:04 PM", isScored: false, hasCorrected: false },
        { id: 6, student: "Floyd Miles", date: "7/15/24 3:04 PM", isScored: false, hasCorrected: true },
        { id: 7, student: "Kathryn Murphy", date: "7/15/24 3:04 PM", isScored: true, hasCorrected: true },
        { id: 8, student: "Floyd Miles", date: "7/15/24 3:04 PM", isScored: false, hasCorrected: true },
        { id: 9, student: "Kathryn Murphy", date: "7/15/24 3:04 PM", isScored: true, hasCorrected: true },
        { id: 10, student: "Floyd Miles", date: "7/15/24 3:04 PM", isScored: false, hasCorrected: true },
        { id: 11, student: "Kathryn Murphy", date: "7/15/24 3:04 PM", isScored: true, hasCorrected: true },
        { id: 12, student: "Floyd Miles", date: "7/15/24 3:04 PM", isScored: false, hasCorrected: true },
        { id: 13, student: "Floyd Miles", date: "7/15/24 3:04 PM", isScored: false, hasCorrected: true },
        { id: 14, student: "Floyd Miles", date: "7/15/24 3:04 PM", isScored: false, hasCorrected: true },
        { id: 15, student: "Kathryn Murphy", date: "7/15/24 3:04 PM", isScored: true, hasCorrected: true },
    ];

    const filterItems = [
        { key: 'completed', label: 'Completed' },
        { key: 'pending', label: 'Pending' },
        { key: 'overdue', label: 'Overdue' },
        { key: 'in-progress', label: 'In Progress' },
    ];

    return (
        <AppLayout hideHeader={true}>

            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.titles}>
                        <div className={styles.filterAndTitle}>
                            <h1 className={styles.mainTitle}>
                                {intl.formatMessage({id: "student.submissions.list.title"})}
                            </h1>
                            <Filter
                                filterItems={filterItems}
                                selectedFilterItems={selectedFilterItems}
                                setSelectedFilterItems={setSelectedFilterItems}
                            />
                        </div>
                    </div>
                    <div className={styles.controls}>
                        <div className={styles.searchContainer}>
                            <SearchOutlined className={styles.searchIcon}/>
                            <input type="text" placeholder={intl.formatMessage({id: "student.submissions.form.search"})} className={styles.searchInput}/>
                        </div>
                    </div>
                </div>
                <h2 className={styles.subTitle}>A Tale of Two Cities</h2>
                <div className={styles.grid}>
                    {submissions.map((submission) => (
                        <SubmissionCard key={submission.id} {...submission} />
                    ))}
                </div>
            </div>
        </AppLayout>
    );
}

export default CourseworkStudentsSubmission;