
const DocIcon = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0151 10H8.84847" stroke="#475467" stroke-width="1.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M13.0151 6.66602H8.84847" stroke="#475467" stroke-width="1.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M16.3485 14.1667V4.16667C16.3485 3.72464 16.1729 3.30072 15.8603 2.98816C15.5478 2.67559 15.1238 2.5 14.6818 2.5H3.84847"
                stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M7.1818 17.5H17.1818C17.6238 17.5 18.0478 17.3244 18.3603 17.0118C18.6729 16.6993 18.8485 16.2754 18.8485 15.8333V15C18.8485 14.779 18.7607 14.567 18.6044 14.4107C18.4481 14.2545 18.2361 14.1667 18.0151 14.1667H9.6818C9.46079 14.1667 9.24883 14.2545 9.09255 14.4107C8.93626 14.567 8.84847 14.779 8.84847 15V15.8333C8.84847 16.2754 8.67287 16.6993 8.36031 17.0118C8.04775 17.3244 7.62383 17.5 7.1818 17.5ZM7.1818 17.5C6.73977 17.5 6.31585 17.3244 6.00329 17.0118C5.69073 16.6993 5.51513 16.2754 5.51513 15.8333V4.16667C5.51513 3.72464 5.33954 3.30072 5.02698 2.98816C4.71442 2.67559 4.29049 2.5 3.84847 2.5C3.40644 2.5 2.98252 2.67559 2.66996 2.98816C2.3574 3.30072 2.1818 3.72464 2.1818 4.16667V5.83333C2.1818 6.05435 2.2696 6.26631 2.42588 6.42259C2.58216 6.57887 2.79412 6.66667 3.01513 6.66667H5.51513"
                stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )
}

export default DocIcon;