import AppLayout from "../Layout/AppLayout";
import PreviewAssignment from "../../Components/PreviewAssignment";
import { NavLink, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useCheckSubmissionMutation,
  useLazyGetAssignmentsQuery,
  useLazyGetCourseWorkStudentQuery,
} from "../../services/assignments.api";
import {
  useGetCourseWorkQuery,
  useLazyGetCourseWorkQuery,
} from "../../services/coursworks.api";
import {
  encapsulateCourseWork,
  encapsulateCourseWorkForSubmission,
} from "../../Utils/encapsulateCourseWork";
import CustomSpin from "../../Components/customSpin/CustomSpin";

const AssignmentDetails = () => {
  const params = useParams();
  const [dataFormated, setDataFormated] = useState(undefined);
  const [isPreview, setIsPreview] = useState(true);

  const [
    triggerCourseWorkById,
    { data: dataCourseWork, isLoading: isLoadingAssignments },
  ] = useLazyGetCourseWorkQuery();
  const [triggerCheckSubmission] = useCheckSubmissionMutation();

  const [
    triggerGetAssignmentsByStudent,
    { data: dataAssignments, isLoading: isLoadingAssignmentsByStudent },
  ] = useLazyGetCourseWorkStudentQuery();

  useEffect(() => {
    if (params?.assignmentId) {
      //triggerCourseWorkById(params?.assignmentId)
      triggerGetAssignmentsByStudent(params?.assignmentId);
    }
  }, [params?.assignmentId]);

  const formatSubmissionData = (studentQuestionAnswers) => {
    
    if (!studentQuestionAnswers?.length) return null;
  
    return studentQuestionAnswers.map((item) => {
      const { question, ...data } = item;
      return {
        ...data,
        ...question,
      };
    });
  };

  const getLatestSubmission = (submissions) => {
    return submissions?.length > 0 ? submissions[submissions.length - 1] : null;
  };

  useEffect(() => {
    if (!dataAssignments) return;
    
    const courseWorkList = dataAssignments?.["hydra:member"]?.[0];
    if (!courseWorkList) return;

    const submissions = dataAssignments?.["hydra:member"]?.[1];
    const { quiz, ...courseWorkData } = courseWorkList;
    const { questions } = quiz || {};

    const latestSubmission = getLatestSubmission(submissions);
    const formattedQuestions = latestSubmission
      ? formatSubmissionData(latestSubmission?.studentQuestionAnswers)
      : null;

    if (courseWorkList.isPracticeMode) {
      setIsPreview(false);
      setDataFormated(
        formattedQuestions
          ? { questions: formattedQuestions , id : courseWorkList?.id}
          : encapsulateCourseWorkForSubmission({
              ...courseWorkData,
              questions,
            })
      );
      return;
    }

    if (!submissions?.length) {
      setIsPreview(false);
      setDataFormated(
        encapsulateCourseWorkForSubmission({
          ...courseWorkData,
          questions,
        })
      );
      return;
    }

    setDataFormated({ 
      questions: formattedQuestions || [] 
    });
  }, [dataAssignments]);

  if (isLoadingAssignmentsByStudent) {
    return <CustomSpin />;
  }

  console.log("dataFormated", dataFormated);
  console.log('dataAssignments?.["hydra:member"]?.[1]',  dataAssignments?.["hydra:member"]?.[1]);
  console.log('dataAssignments?.["hydra:member"]?.[0]',  dataAssignments?.["hydra:member"]?.[0]);

  return (
    <AppLayout hideHeader={true}>
      <div style={{ paddingRight: 20, paddingTop: 30 }}>
        <PreviewAssignment assignments={dataFormated} isPreview={isPreview} />
      </div>
    </AppLayout>
  );
};

export default AssignmentDetails;
