import { api } from './api';

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({


        getClassroomCount: builder.query({
            query: (data) => ({
                params:{state:data.state},
                url: `api/classrooms/get-classroom-count/${data.id}`,
                method: 'GET',
                // headers: {'Content-Type': 'application/ld+json'},

            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'ClassRooms', id: 'LIST' },
                    ],
        }),
        createClassRoom: builder.mutation({
            query: (formData) => ({
                url: '/api/classrooms',
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags: [{type: 'ClassroomsFilter', id: 'LIST'}],
        }),

        editClassroom: builder.mutation({
            query: (classroomData) => {
                return {
                    url: `/api/classrooms/${classroomData.id}`,
                    method: 'PATCH',
                    headers: {'Content-Type': 'application/merge-patch+json'},

                    body: JSON.stringify(classroomData),
                }
            },
            invalidatesTags: [{type: 'ClassroomsFilter', id: 'LIST'}],
        }),


        deleteClassroom: builder.mutation({
            query: ({ id }) => ({
                url: `/api/classrooms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'ClassroomsFilter', id: 'LIST' },
                    ],
        }),
        getClassroom: builder.query({
            query: (classId) => ({
                url: `/api/classrooms/${classId}`,
                method: 'GET',
            }),
            providesTags: (result, error, { assignmentId }) => [{ type: 'Classrooms', id: assignmentId }],
        }),
        getClassroomsByTeacher: builder.query({
            query: (data) => ({
                url: `/api/classrooms?teacher=${data.teacherId}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [{ type: 'ClassroomsFilter', id: 'LIST' }],
        }),

        getClassroomsByStudent: builder.query({
            query: (data) => ({
                url: `/api/classrooms?students=${data.students}`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [{ type: 'ClassroomsFilterStudents', id: 'LIST' }],
        }),

    }),
});

export const { useLazyGetClassroomQuery,useCreateClassRoomMutation,useLazyGetClassroomCountQuery, useEditClassroomMutation, useDeleteClassroomMutation,useLazyGetClassroomsByTeacherQuery,useLazyGetClassroomsByStudentQuery } = authApi;