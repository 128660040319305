
import React, { useState } from "react";
import {Button, Input, Select, notification, Spin, message} from "antd";
import "./newRubric.css";
import AppLayout from "../Layout/AppLayout";
import Criteria from "../../Components/criteria/Criteria";
import { PlusCircleFilled, ArrowLeftOutlined, RollbackOutlined } from "@ant-design/icons"
import {Link, useNavigate} from "react-router-dom";
import { useFormik } from 'formik';
import { object, string, array, number } from 'yup';
import {useCreateRubricMutation} from "../../services/rubrics.api";
import {useGetMeQuery} from "../../services/auth.api";
import {useDispatch} from "react-redux";
import {setNotification} from "../../redux/app/app.slice";

import {useIntl} from "react-intl";

const NewRubric = () => {

    const [createRubric, {isLoading: isLoadingNewRubric}] = useCreateRubricMutation()
    let teacher=null
    const { data: authenticatedUser, error } = useGetMeQuery();
    const dispatch = useDispatch();
    const intl = useIntl();

    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            title: '',
            format: 'table',
            type: 'essay',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            criterias: [
                {
                    title: '',
                    description: '',
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                    levels: [
                        {
                            title: '',
                            point:0,
                            description: '',
                            created_at: new Date().toISOString(),
                            updated_at: new Date().toISOString(),
                        }
                    ]
                },
            ],
        },
        validationSchema: object({
            title: string().required('Required'),
            criterias: array().of(
                object({
                    title: string().required('Required'),
                    levels: array().of(
                        object({
                            point: number().required('Required').min(0, 'Must be greater than or equal to 0'),
                            description: string().required('Required'),
                        })
                    )
                })
            )
        }),
        onSubmit: async (values) => {
            if(authenticatedUser){
                teacher= "/api/teachers/"+authenticatedUser.id
            }
            console.log("formik values ==>", values);

            if(values.criterias?.length === 0){
                api["error"]({
                    message: 'Rubric cannot be saved',
                    description:'Please add at least one criteria to your rubric.',
                    placement: 'top',
                    style: {
                        color:"red",
                    },
                    showProgress: true,
                    pauseOnHover:true,

                });
            }else{
                console.log("formik values ==>", values);
                const rubricData = {
                    ...values,
                    teacher:teacher,
                };
                 const {data, error} = await createRubric(rubricData);
                     if(data){
                         //message.success('Rubric created successfully');
                         dispatch(setNotification({message: 'Rubric created successfully', type: 'success'}));
                        formik.resetForm();
                        // redirect to rubric list
                        navigate('/rubric');
                     }
                   if(error){
                       console.log("error", error)
                       dispatch(setNotification({message: 'Rubric could not be created', type: 'error'}));
                   }

            }
        },
    });

    const addCriteria = () => {
        formik.setFieldValue('criterias', [
            ...formik.values.criterias,
            { title: '',format: '', type: '', created_at: new Date().toISOString(), updated_at: new Date().toISOString() , levels: [{ title: '', point:0, description: '',  created_at: new Date().toISOString(), updated_at: new Date().toISOString()}] },
        ]);
    };

    const handleDeleteCriteria = (indexToDelete) => {
        const updatedCriteria = formik.values.criterias.filter((_, i) => i !== indexToDelete);
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const handleCriteriaTitleChange = (criteriaIndex,field, value) => {
        const updatedCriteria = [...formik.values.criterias];
        updatedCriteria[criteriaIndex][field] = value;
        //updatedCriteria[criteriaIndex].description = value;
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const handleScoreChange = (criteriaIndex, scoreIndex, field, value) => {
        const updatedCriteria = [...formik.values.criterias];
        updatedCriteria[criteriaIndex].levels[scoreIndex][field] = value;
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const addScore = (criteriaIndex) => {
        const updatedCriteria = [...formik.values.criterias];
        updatedCriteria[criteriaIndex].levels.push({ title: '', point:0, description: '', created_at: new Date().toISOString(), updated_at: new Date().toISOString()});
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const deleteScore = (criteriaIndex, scoreIndex) => {
        const updatedCriteria = [...formik.values.criterias];
        if (scoreIndex !== 0) {
            updatedCriteria[criteriaIndex].levels = updatedCriteria[criteriaIndex].levels.filter((_, i) => i !== scoreIndex);
        } else {
            updatedCriteria[criteriaIndex].levels = [{ title: '', point:0, description: '' }];
        }
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const deleteAllScores = (criteriaIndex) => {
        const updatedCriteria = [...formik.values.criterias];
        updatedCriteria[criteriaIndex].levels = [{ title: '', point:0, description: '' }];
        formik.setFieldValue('criterias', updatedCriteria);
    };

    return (
        <AppLayout hideHeader={true}>
            <div className="newRubricContent">
                <form onSubmit={formik.handleSubmit}>
                    <div className="newRubricContent__header">
                        <div className={"block__back__title"}>
                            <div className={"newRubricContent__back"}>
                                <Link to={"/rubric"}><RollbackOutlined
                                    style={{fontSize: "15px", fontWeight: "600"}}/>{intl.formatMessage({id: "rubric.create.form.button.back"})}</Link>
                            </div>
                            <div className="newRubricContent__header-title">
                                <h1>{intl.formatMessage({id: "rubric.create.form.title"})}</h1>
                            </div>
                        </div>

                        <div className="newRubricContent__header-new">
                            <Button type="primary" htmlType="submit"> {intl.formatMessage({id: "rubric.create.form.button.save"})} </Button>
                            {contextHolder}
                        </div>
                    </div>
                    <section>
                        <div className="rubric__form">
                            <div className="rubric__form-header">
                                <div>
                                    <label htmlFor="name" className="rubricNameInput">
                                        {intl.formatMessage({id: "rubric.create.form.field.label.name"})}
                                    </label>
                                    <Input
                                        id="title"
                                        name="title"
                                        placeholder={intl.formatMessage({id: "rubric.create.form.field.placeholder.name"})}
                                        style={{width: "22rem"}}
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.title && formik.errors.title ?
                                        <div className='errorMessage'>{formik.errors.title}</div> : null}
                                </div>
                                <div>
                                    <label className="gradeSelector">
                                        {intl.formatMessage({id: "rubric.create.form.field.label.format"})}
                                    </label>
                                    <Select
                                        style={{width: "12rem"}}
                                        value={formik.values.format}
                                        onChange={(value) => formik.setFieldValue('format', value)}
                                        onBlur={() => formik.setFieldTouched('format')}
                                        options={[
                                            {value: 'table', label: 'Table'},
                                            {value: 'text', label: 'Text'},
                                        ]}
                                        placeholder={intl.formatMessage({id: "rubric.create.form.field.placeholder.format"})}
                                        defaultValue="table"
                                    />
                                    {formik.touched.format && formik.errors.format ?
                                        <div className='errorMessage'>{formik.errors.format}</div> : null}
                                </div>

                                <div>
                                    <label className="gradeSelector">
                                        {intl.formatMessage({id: "rubric.create.form.field.label.type"})}
                                    </label>
                                    <Select
                                        style={{width: "12rem"}}
                                        value={formik.values.type}
                                        onChange={(value) => formik.setFieldValue('type', value)}
                                        onBlur={() => formik.setFieldTouched('type')}
                                        options={[
                                            {value: 'shortAnswer', label: intl.formatMessage({id: "rubric.create.form.field.type.option.shortAnswer"})},
                                            {value: 'essay', label: intl.formatMessage({id: "rubric.create.form.field.type.option.essay"})},
                                        ]}
                                        placeholder={intl.formatMessage({id: "rubric.create.form.field.placeholder.type"})}
                                        defaultValue="essay"
                                    />
                                    {formik.touched.type && formik.errors.type ?
                                        <div className='errorMessage'>{formik.errors.type}</div> : null}
                                </div>
                            </div>
                            {formik.values.criterias.map((criteria, index) => (
                                <Criteria
                                    key={index}
                                    criteria={criteria}
                                    onDelete={() => handleDeleteCriteria(index)}
                                    onTitleChange={(field, value) => handleCriteriaTitleChange(index, field, value)}
                                    onScoreChange={(scoreIndex, field, value) => handleScoreChange(index, scoreIndex, field, value)}
                                    addScore={() => addScore(index)}
                                    deleteScore={(scoreIndex) => deleteScore(index, scoreIndex)}
                                    deleteAllScores={() => deleteAllScores(index)}
                                    error={formik.errors.criterias && formik.errors.criterias[index]}
                                />
                            ))}
                            <Button className="addCriteria" onClick={addCriteria}>
                                <PlusCircleFilled style={{fontSize: "18px"}}/>
                                <span>
                                    {intl.formatMessage({id: "rubric.create.form.button.add.criteria"})}
                                </span>
                            </Button>
                        </div>
                    </section>
                </form>
            </div>
        </AppLayout>
    )
}

export default NewRubric
