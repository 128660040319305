import { createSlice } from '@reduxjs/toolkit';

export const  initialState = {
    locale: 'en', // Default language
};

export const localeSlice = createSlice({
    name: 'locale',
    initialState:initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.locale = action.payload;
        },
    },
});

export const { changeLanguage } = localeSlice.actions;

export default localeSlice.reducer;