import React from 'react';
import { Modal, Input, Select } from 'antd';
import { Formik } from 'formik';

import {useIntl} from 'react-intl';
import * as Yup from "yup";

const ClassModal = ({ isModalOpen, handleCancel, handleSubmit, mode, initialValues }) => {
    const isEditMode = mode === 'edit';
    const intl = useIntl();

    return (
        <Modal open={isModalOpen} onCancel={handleCancel} centered footer={null} width={685}>
            <div className="createClassModal__header">
                <h1>{isEditMode ? intl.formatMessage({id: "classroom.form.title.edit"}) : intl.formatMessage({id: "classroom.form.title.create"})}</h1>
                <h2>
                    {intl.formatMessage({id: "classroom.form.description"})}
                </h2>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                    name: Yup.string().required(intl.formatMessage({ id: "classroom.form.error.name.required" })),
                    subject: Yup.string().required(intl.formatMessage({ id: "classroom.form.error.subject.required" })),
                    grade: Yup.string().required(intl.formatMessage({ id: "classroom.form.error.grade.required" })),
                })}
                onSubmit={async (values) => {
                    handleSubmit(values);
                }}
                enableReinitialize={true}
            >
                {({ values, setFieldValue, handleChange, handleSubmit, errors, touched }) => (
                    <form className="createClassModal__bottom" onSubmit={handleSubmit}>
                        <div className="createClassModal__bottom-classTitle">
                            <label htmlFor="classTitle">{intl.formatMessage({id: "classroom.form.label.classTitle"})}</label>
                            <Input
                                name="name"
                                value={values.name}
                                placeholder={intl.formatMessage({id: "classroom.form.label.classTitle.placeholder"})}
                                id="name"
                                onChange={handleChange}
                            />
                            {errors.name && touched.name && <div className="error-message">{errors.name}</div>}
                        </div>
                        <div className="createClassModal__bottom-SubjectGrade">
                            <div className="createClassModal__bottom-SubjectGrade-subject">
                                <label>
                                    <span>{intl.formatMessage({id: "classroom.form.label.subject.title"})}</span>
                                    <span>{intl.formatMessage({id: "classroom.form.label.subject.description"})}</span>
                                </label>
                                <Select
                                    name="subject"
                                    value={values.subject}
                                    onChange={value => setFieldValue('subject', value)}
                                    options={[
                                        { value: 'Math', label: intl.formatMessage({id: "classroom.form.subject.options.math"}) },
                                        { value: 'English', label: intl.formatMessage({id: "classroom.form.subject.options.english"}) },
                                        { value: 'Science', label: intl.formatMessage({id: "classroom.form.subject.options.science"}) },
                                        { value: 'History', label: intl.formatMessage({id: "classroom.form.subject.options.history"}) },
                                        { value: 'Geography', label: intl.formatMessage({id: "classroom.form.subject.options.geography"}) },
                                    ]}
                                />
                                {errors.subject && touched.subject && <div className="error-message">{errors.subject}</div>}

                            </div>
                            <div className="createClassModal__bottom-SubjectGrade-grade">
                                <label>
                                    <span>{intl.formatMessage({id: "classroom.form.label.grade.title"})}</span>
                                    <span>{intl.formatMessage({id: "classroom.form.label.grade.description"})}</span>
                                </label>
                                <Select
                                    name="grade"
                                    value={values.grade}
                                    onChange={value => setFieldValue('grade', value)}
                                    options={[
                                        { value: '1st', label: '1st' },
                                        { value: '2nd', label: '2nd' },
                                        { value: '3rd', label: '3rd' },
                                        { value: '4th', label: '4th' },
                                        { value: '5th', label: '5th' },
                                        { value: '6th', label: '6th' },
                                        { value: '7th', label: '7th' },
                                        { value: '8th', label: '8th' },
                                        { value: '9th', label: '9th' },
                                        { value: '10th', label: '10th' },
                                        { value: '11th', label: '11th' },
                                    ]}
                                />
                                {errors.grade && touched.grade && <div className="error-message">{errors.grade}</div>}
                            </div>
                        </div>
                        <button type="submit" className="createClassBtn">
                            {isEditMode
                                ? intl.formatMessage({id: "classroom.form.button.edit"})
                                : intl.formatMessage({id: "classroom.form.button.create"})}
                        </button>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default ClassModal;