import React from 'react';

import {
    BrowserRouter,
    Route, Routes
} from 'react-router-dom';
import Correction from "../Pages/Correction/Correction";
import SignIn from "../Pages/Sign-in/SignIn";
import SignUp from "../Pages/auth/signUp-flow/SignUpFlow";
import ForgetPassword from "../Components/ForgetPassword/ForgetPassword";
import NewPassword from "../Components/NewPassword/NewPassword";
import ProtectedRoute from "../Components/protectedRoute/protectedRoute";
import Layout from "./layout";
import Assignment from "../Pages/Assignments/Assignment";
import Rubric from "../Pages/rubric/Rubric";
import NewRubric from "../Pages/new-rubric/NewRubric";
import Submissions from "../Pages/submissions/Submissions";
import SubmissionDetails from "../Pages/submissionDetails/SubmissionDetails";
import StudentSubmissionDetails from "../Pages/StudentSubmissionDetails/StudentSubmissionDetails";
import Classes from "../Pages/classes/Classes";
import StudentCourseworkSubmission from "../Pages/studentCourseworkSubmission/StudentCourseworkSubmission";
import NotFound from "../Pages/notFound/NoutFound";
import GoogleClassroom from "../Pages/googleClassroom/GoogleClassroom";
import NewAssignment from "../Pages/newAssignment";
import AssignmentPreview from "../Pages/AssignmentPreview";
import CorrectionPage from "../Pages/correctionPage";
import AssignmentDetails from "../Pages/AssignmentDetails";
import CourseworkStudentsSubmission from "../Pages/courseworkStudentsSubmission";


const MainRoutes = () => {


  return (
      <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute role="ROLE_ADMIN" >
               <Layout/>
              </ProtectedRoute>

            }
          >
              <Route index={true} element={<Classes />} />
              <Route path="/correction" element={<Correction />} />
              <Route path="/rubric" element={<Rubric />} />
              <Route path="/rubric/new" element={<NewRubric />} />
              <Route path="/submissions" element={<Submissions />} />
              <Route path="/submission/:id/show" element={<SubmissionDetails />} />
              <Route path="/coursework" element={<Assignment />} />
              <Route path="/student/submission" element={<StudentCourseworkSubmission />} />
              <Route path="/get-google-classroom" element={<GoogleClassroom />} />
              <Route path="/assignment/create/:mode" element={<NewAssignment />} />
              <Route path="/assignment/edit/:id" element={<NewAssignment />} />
              <Route path="/correction-page" element={<CorrectionPage />} />
              <Route path="/assignment/preview/:assignmentId" element={<AssignmentPreview />} />
              <Route path="/assignment/preview/:id" element={<AssignmentDetails />} />
              <Route path="/students/submissions" element={<CourseworkStudentsSubmission />} />

          </Route>

          <Route
              path="/"
              element={
                  <ProtectedRoute role="ROLE_USER" >
                      <Layout/>
                  </ProtectedRoute>

              }
          >
              <Route path="/courseworks/:assignmentId" element={<AssignmentDetails />} />

          </Route>
          <Route exact path="/sign-in" element={ <SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/password-resets" element={<ForgetPassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="*" element={<NotFound />} />
      </Routes>
  );
};

export default MainRoutes;
