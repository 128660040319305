

const Index = () => {

    return (
        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M45.1673 7.41797H20.5007C18.7325 7.41797 17.0368 8.12035 15.7866 9.37059C14.5364 10.6208 13.834 12.3165 13.834 14.0846V67.418C13.834 69.1861 14.5364 70.8818 15.7866 72.132C17.0368 73.3823 18.7325 74.0846 20.5007 74.0846H60.5006C62.2688 74.0846 63.9645 73.3823 65.2147 72.132C66.4649 70.8818 67.1673 69.1861 67.1673 67.418V42.7513"
                stroke="currentColor" stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.16602 20.752H20.4993" stroke="currentColor" stroke-width="6.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M7.16602 34.0859H20.4993" stroke="currentColor" stroke-width="6.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M7.16602 47.418H20.4993" stroke="currentColor" stroke-width="6.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M7.16602 60.752H20.4993" stroke="currentColor" stroke-width="6.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M71.7625 19.5051C73.0904 18.1773 73.8364 16.3763 73.8364 14.4985C73.8364 12.6206 73.0904 10.8196 71.7625 9.4918C70.4347 8.16395 68.6337 7.41797 66.7559 7.41797C64.878 7.41797 63.077 8.16395 61.7492 9.4918L45.0492 26.1985C44.2567 26.9905 43.6766 27.9696 43.3625 29.0451L40.5725 38.6118C40.4889 38.8986 40.4839 39.2027 40.558 39.4921C40.6322 39.7815 40.7827 40.0457 40.994 40.257C41.2053 40.4682 41.4695 40.6188 41.7589 40.693C42.0483 40.7671 42.3524 40.7621 42.6392 40.6785L52.2059 37.8885C53.2814 37.5744 54.2605 36.9943 55.0525 36.2018L71.7625 19.5051Z"
                stroke="currentColor" stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default Index