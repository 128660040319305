import { MoreOutlined, LinkOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import copyCodeIcon from "../../assets/images/copyCodeIcon.svg";
import classLesson from "../../assets/images/class-lesson.svg"
import classVector from "../../assets/images/Vector-primary.svg"
import "./style.css"
import {useIntl} from "react-intl";

const StudentClassCard = ({
                       className,
                        onClick,
                       subject,
                        courswork,
                       students,
                              teacher
                   }) => {

    const intl = useIntl();

    return (
        <div className="classStudentContainer"  onClick={onClick}>
            <div className="classContainer__content">
                <h1>{className}</h1>
                <div>
                    <span className="classContainer__key">{intl.formatMessage({id: "classroom.class.subject"})} : </span>
                    <span className="classContainer__value">{subject}</span>
                </div>
                <div>
                    <span className="classContainer__key">{intl.formatMessage({id: "classroom.class.assignment"})} : </span>
                    <span className="classContainer__value">{courswork}</span>
                </div>
                <div>
                    <span className="classContainer__key">{students>1 ? `${intl.formatMessage({id: "classroom.class.submission.count.student"} )}s` : `${intl.formatMessage({id: "classroom.class.submission.count.student"} )}` } : </span>
                    <span className="classContainer__value">{students}</span>
                </div>
                <div>
                    <span className="classContainer__key">{intl.formatMessage({id: "classroom.class.coursework.teacher"})} : </span>
                    <span className="classContainer__value">{teacher}</span>
                </div>
            </div>
                <img src={classVector} alt="class vector" className="classVectorBg"/>
                <img src={classLesson} alt="class vector" className="classLessonIcon"/>

        </div>
    );
};


export default StudentClassCard;
