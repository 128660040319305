import * as ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from './redux/store';
import * as React from 'react';
import Root from "./routes/Root";
import { Spin } from "antd";
import './index.css';
import messageEn from "./locales/en.json";
import messageFr from "./locales/fr.json";
import { IntlProvider } from 'react-intl';
import { selectLocale } from "./redux/locale/locale.selectors";

const { store, persistor } = configureAppStore();

const root = ReactDOM.createRoot(document.getElementById('root'));

// Define available locales as a constant
const SUPPORTED_LOCALES = ['en', 'fr'];
const DEFAULT_LOCALE = 'en';

const messages = {
    en: messageEn,
    fr: messageFr
};

const Language = ({ children }) => {
    const locale = useSelector(selectLocale);

    // Ensure we have a valid locale
    const currentLocale = SUPPORTED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE;

    return (
        <IntlProvider
            locale={currentLocale}
            messages={messages[currentLocale]}
            defaultLocale={DEFAULT_LOCALE}
            onError={(err) => {
                // Handle missing translation errors gracefully
                if (err.code === 'MISSING_TRANSLATION') {
                    console.warn('Missing translation:', err.message);
                    return;
                }
                throw err;
            }}
        >
            {children}
        </IntlProvider>
    );
};

const Skeleton = () => (
    <div className="loading">
        <Spin className="loading__spin" />
    </div>
);

root.render(
    <Provider store={store}>
        <Language>
            <PersistGate loading={<Skeleton />} persistor={persistor}>
                <HelmetProvider>
                    <Root />
                </HelmetProvider>
            </PersistGate>
        </Language>
    </Provider>
);