

const InsightIcon = () => {

    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5151 16.6673V8.33398" stroke="#475467" stroke-width="1.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M10.5151 16.6673V3.33398" stroke="#475467" stroke-width="1.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M5.51514 16.666V11.666" stroke="#475467" stroke-width="1.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>

    )
}

export default InsightIcon;