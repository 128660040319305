import EditRubricTable from '../editRubricTable/EditRubricTable';
import {message, Modal} from 'antd';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {setNotification} from "../../../redux/app/app.slice";

import {useEditRubricMutation} from "../../../services/rubrics.api";



const EditRubricModal = ({isEditModalOpen, setIsEditModalOpen, rubricItemDataEdit}) => {

    const [updateRubric, {  isLoadingRubricUpdating }] = useEditRubricMutation();
    const intl =useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        if (rubricItemDataEdit) {
            formik.setValues({
                title: rubricItemDataEdit.title || '',
                type: rubricItemDataEdit.type || '',
                created_at: rubricItemDataEdit.created_at || new Date().toISOString(),
                updated_at: rubricItemDataEdit.updated_at || new Date().toISOString(),
                criterias: (rubricItemDataEdit.criterias || []).map(criteria => ({
                    title: criteria.title || '',
                    description: criteria.description || '',
                    created_at: criteria.created_at || new Date().toISOString(),
                    updated_at: criteria.updated_at || new Date().toISOString(),
                    levels: (criteria.levels || []).map(level => ({
                        title: level.title || '',
                        point: level.point || 0,
                        description: level.description || '',
                        created_at: level.created_at || new Date().toISOString(),
                        updated_at: level.updated_at || new Date().toISOString(),
                    }))
                }))
            });
        }
    }, [rubricItemDataEdit]);

    const formik = useFormik(
        {
            //enableReinitialize: true,
        initialValues : {
            title: rubricItemDataEdit.title || '',
            type: rubricItemDataEdit.type || '',
            created_at: rubricItemDataEdit.created_at || new Date().toISOString(),
            updated_at: rubricItemDataEdit.updated_at || new Date().toISOString(),
            criterias: (rubricItemDataEdit.criterias || []).map(criteria => ({
                title: criteria.title || '',
                description: criteria.description || '',
                created_at: criteria.created_at || new Date().toISOString(),
                updated_at: criteria.updated_at || new Date().toISOString(),
                levels: (criteria.levels || []).map(level => ({
                    title: level.title || '',
                    point: level.point || 0,
                    description: level.description || '',
                    created_at: level.created_at || new Date().toISOString(),
                    updated_at: level.updated_at || new Date().toISOString(),
                }))
            }))
        },

        onSubmit: async (values) => {
            console.log("formik values", values)
            //formik.resetForm()
            const currentDate = new Date().toISOString();
            const rubricData = {
                ...values,
                updated_at: currentDate,
                id: rubricItemDataEdit.id
            };
            const { data, error } = await updateRubric(rubricData);
            if(data){
                setIsEditModalOpen(!isEditModalOpen)
                dispatch(
                    setNotification(
                        {
                            type: 'success',
                            message: intl.formatMessage({id: "rubric.message.edit.success.description"})
                        }
                    )
                )
            }
            if (error) {
                dispatch(
                    setNotification(
                        {
                            type: 'error',
                            message: intl.formatMessage({id: "rubric.message.edit.error.description"})
                        }
                    )
                )
            }

        }
    })

    const handleCancel=()=>{
        setIsEditModalOpen(!isEditModalOpen)
    }

    const handleTitleChange = (criteriaIndex, field, value) => {
        const updatedCriteria = [...formik.values.criterias];
        updatedCriteria[criteriaIndex][field] = value;
        formik.setFieldValue('criterias', updatedCriteria);
    }
    const handleLevelChange = (criteriaIndex,levelIndex, field, value) => {
        const updatedCriteria = [...formik.values.criterias];
        updatedCriteria[criteriaIndex].levels[levelIndex][field] = value;
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const handleRubricTitleChange = (field, value) => {
        formik.setFieldValue(field, value);
    }

    return (
        <Modal open={isEditModalOpen} onCancel={handleCancel} footer={null} width={920} style={{top: 38}}>
            <form onSubmit={formik.handleSubmit}>
                <h1 style={{fontSize: "21px"}} className={"rubric__title"}>
                   <input
                       value= {formik.values?.title}
                       onChange={(value) => handleRubricTitleChange('title', value.target.value)}
                   />
                </h1>
                {formik.values?.criterias.map((item, index) => (
                    <EditRubricTable
                        key={index}
                        criteria={item}
                        handleLevelChange={(levelIndex, field, value) => handleLevelChange(index,levelIndex, field, value)}
                        handleTitleChange={(field, value) => handleTitleChange(index, field, value)}
                    />
                ))}
                <button className={"btn-save"} type={"submit"}>{ intl.formatMessage({id: "rubric.form.button.edit.text"}) }</button>
            </form>
        </Modal>
)
}

export default EditRubricModal
