import React, {useEffect} from 'react';
import './Notification.css';
import {useDispatch} from "react-redux";
import {setNotification} from "../../redux/app/app.slice";

const Notification = ({notification}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        if(notification){
            setTimeout(()=>{
                dispatch(setNotification(null))
            },3000)
        }
    }, [notification]);
    return notification ? (
        <div className={"custom__notification"}>
            <div className={`notification ${notification.type}`}>
                {notification.message}
            </div>
        </div>
    ) : null;
};

export default Notification;