

const AvatarStudent = () => {

    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.7735 13.6529C26.9973 13.5542 27.1872 13.392 27.3197 13.1865C27.4522 12.9809 27.5215 12.7409 27.5189 12.4964C27.5164 12.2518 27.4422 12.0134 27.3055 11.8106C27.1687 11.6078 26.9755 11.4495 26.7498 11.3554L16.036 6.47545C15.7103 6.32688 15.3565 6.25 14.9985 6.25C14.6405 6.25 14.2867 6.32688 13.961 6.47545L3.24853 11.3504C3.02599 11.4479 2.83667 11.6081 2.70373 11.8115C2.5708 12.0148 2.5 12.2525 2.5 12.4954C2.5 12.7384 2.5708 12.9761 2.70373 13.1794C2.83667 13.3828 3.02599 13.543 3.24853 13.6404L13.961 18.5254C14.2867 18.674 14.6405 18.7509 14.9985 18.7509C15.3565 18.7509 15.7103 18.674 16.036 18.5254L26.7735 13.6529Z"
                stroke="#000095" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M27.5 12.5V20" stroke="#000095" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M7.5 15.625V20C7.5 20.9946 8.29018 21.9484 9.6967 22.6517C11.1032 23.3549 13.0109 23.75 15 23.75C16.9891 23.75 18.8968 23.3549 20.3033 22.6517C21.7098 21.9484 22.5 20.9946 22.5 20V15.625"
                stroke="#000095" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default AvatarStudent