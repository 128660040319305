import React, {useState} from "react";
import { Modal, Card, Button, Typography, Radio } from "antd";
import { EditOutlined, BulbOutlined } from "@ant-design/icons";
import CustomHandWritingIcon from '../../assets/icons/CustomHandWritingIcon';
import AIIcon from '../../assets/icons/AIIcon';
import styles from './customCourseworkModal.module.css';
import "./style.css";
import {useIntl} from "react-intl";

const { Title, Text } = Typography;


/**
 * A modal to select a method of creating a coursework
 * @param {{visible:boolean, onCancel:Function, onContinue:Function}} props
 * @returns {JSX.Element}
 */
const CustomCourseworkModal = ({ visible, onCancel, onContinue }) => {

    const intl = useIntl();
    const [mode, setMode] = useState(null);

    return (
        <Modal
            title={<Title level={3}>{intl.formatMessage({id: "assignment.create.modal.title"})}</Title>}
            visible={visible}
            onCancel={onCancel}
            footer={null}
            centered
            className={`custom__modal ${styles.customModal}`}
        >
            <Text className={"sub__title"}>
                {intl.formatMessage({id: "assignment.create.modal.subtitle"})}
            </Text>
            <div
                style={{

                }}
                className={`${styles.customCardContainer}`}
            >
                <div>
                    <Card
                        hoverable
                        className={`${styles.customCard} ${mode === "custom" ? styles.selected : ""}`}
                        onClick={() => setMode("custom")}
                    >
                        <CustomHandWritingIcon className={`${styles.customIcon}`} />
                    </Card>

                    <Text className={`${styles.customText} ${mode === "custom" ? styles.selected : ""}`}>
                        {intl.formatMessage({id: "assignment.create.modal.choice.custom"})}
                    </Text>
                </div>
                <div>
                    <Card
                        hoverable
                        className={`${styles.customCard} ${mode === "ai" ? styles.selected : ""}`}
                        onClick={() => setMode('ai')}
                    >
                        <AIIcon />

                    </Card>
                    <Text className={`${styles.customText} ${mode === "ai" ? styles.selected : ""} `}>
                        {intl.formatMessage({id: "assignment.create.modal.choice.ai"})}
                    </Text>
                </div>
            </div>
            <Button
                type="primary"
                className={`${styles.customButton}`}
                onClick={() => {
                    if (mode)
                        window.location.href = `/assignment/create/${mode}`
                }}
            >
                {intl.formatMessage({id: "assignment.create.modal.button.continue"})}
            </Button>
        </Modal>
    );
};


export default CustomCourseworkModal;
