import React, { useState } from 'react';
import styles from './correction.module.css';
import AppLayout from "../Layout/AppLayout";
import AssignmentCorrectionsPreview from "../../Components/assignmentCorrectionsPreview";

const assignmentsQCM = {
    title: "Biology Question",
    handwrittenDocs: true,
    aiAssistance: false,
    uploadDocs: true,
    introduction:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    questions: [
        {
            type: "Multiple Choice",
            context: true,
            justification: true,
            image:
                "https://s3-alpha-sig.figma.com/img/71ae/0e27/71b554682e39ab27fbc6ac2a51b733e2?Expires=1737331200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lmvYWyA67vaYNtITUVcYxOh7tSNsy22LQO6Dm2GskSpim6qrVEElGaoSJQbRwv1vYDaOVMtzgoM-8nwAbqt2ki1dc6k5LpldDAMJ9ZjKhbd9awVIHw~3xRZTYMQYgEXaPuI8~5HO4mUkMWQ~UUw2kkp-MeMrrt7XOKxpYZrFQlxMHVYb~z~FbEIgBHqyvW3NW6jWalwqDfmOXz9F5ey28rfGY7AfguIw62VHBi8ZiADpLgIz3OHucC2jwqgZpp~ZPP-VuhgCnI8bvjgaHdV~S3MPS9akpYK~TBiSQ2C16sFiGBy0KsLFatAEllIbLCuNjeEvL1lm25Q~dF3tKPIb6A__",
            text: "What is the powerhouse of the cell?",
            answerMode: "multi",
            options: [
                { text: "Nucleus", isCorrect: false },
                { text: "Mitochondria", isCorrect: true },
                { text: "Ribosome", isCorrect: false },
                { text: "Golgi apparatus", isCorrect: false },
            ],
            answer: [1, 3],
            answerJustification: "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual",
        },
        {
            type: "Multiple Choice",
            context: false,
            justification: true,
            text: "Which planets are gas giants?",
            answerMode: "multi",
            options: [
                { text: "Earth", isCorrect: false },
                { text: "Mars", isCorrect: false },
                { text: "Jupiter", isCorrect: true },
                { text: "Saturn", isCorrect: true },
            ],
            answer: [1, 2],
            answerJustification: "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual",
        },
        {
            type: "Multiple Choice",
            context: true,
            justification: true,
            text: "What is Newton's third law?",
            answerMode: "multi",
            options: [
                {
                    text: "For every action, there is an equal and opposite reaction.",
                    isCorrect: true,
                },
                { text: "Force equals mass times acceleration.", isCorrect: false },
                {
                    text: "Energy is neither created nor destroyed.",
                    isCorrect: false,
                },
                { text: "The speed of light is constant.", isCorrect: false },
            ],
            answer: [1, 2],
            answerJustification: "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual",
        },
        {
            type: "Short answer",
            context: true,
            text: "What is the chemical symbol for water?",
            answerMode: "shortAnswer",
            correctAnswer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
            type: "Short answer",
            context: false,
            text: "Who wrote the play 'Romeo and Juliet'?",
            answerMode: "shortAnswer",
            correctAnswer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
            type: "Essay",
            context: true,
            text: "Explain the significance of the Industrial Revolution on modern society.",
            answerMode: "essay",
            correctAnswer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        },
        {
            type: "Essay",
            context: false,
            text: "What role does renewable energy play in the fight against global warming?",
            answerMode: "essay",
            correctAnswer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
        },
    ],
};

const CorrectionPage = () => {
    return (
        <AppLayout hideHeader={true}>
            <AssignmentCorrectionsPreview assignments={assignmentsQCM} isPreview={false} />
        </AppLayout>
    );

};

export default CorrectionPage;