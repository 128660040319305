

const AIIcon = () => {

    return (
        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M40.502 17.4162C40.506 16.083 40.2433 14.7624 39.7294 13.5322C39.2155 12.302 38.4607 11.1869 37.5096 10.2527C36.5585 9.3184 35.4301 8.58376 34.1909 8.09195C32.9517 7.60015 31.6266 7.36111 30.2937 7.38889C28.9607 7.41667 27.6468 7.71072 26.4292 8.25374C25.2115 8.79675 24.1148 9.57777 23.2034 10.5509C22.292 11.524 21.5844 12.6695 21.1222 13.92C20.66 15.1706 20.4526 16.5009 20.512 17.8328C18.5527 18.3366 16.7337 19.2797 15.1928 20.5906C13.6519 21.9014 12.4295 23.5458 11.6182 25.399C10.8069 27.2523 10.4279 29.2658 10.51 31.2872C10.5921 33.3086 11.1331 35.2848 12.092 37.0662C10.406 38.436 9.08013 40.1969 8.22984 42.196C7.37954 44.195 7.03056 46.3715 7.21325 48.5361C7.39594 50.7008 8.10476 52.788 9.27806 54.6163C10.4514 56.4445 12.0536 57.9584 13.9454 59.0262C13.7118 60.8336 13.8512 62.6698 14.355 64.4213C14.8588 66.1727 15.7164 67.8023 16.8747 69.2094C18.0329 70.6164 19.4674 71.7711 21.0894 72.6021C22.7115 73.433 24.4866 73.9226 26.3053 74.0406C28.124 74.1586 29.9475 73.9026 31.6633 73.2882C33.3791 72.6739 34.9508 71.7143 36.2812 70.4687C37.6117 69.2232 38.6726 67.7181 39.3986 66.0464C40.1245 64.3747 40.5001 62.572 40.502 60.7495V17.4162Z"
                stroke="currentColor" stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M30.5 44.0853C33.2985 43.1008 35.7423 41.3086 37.5222 38.9353C39.3022 36.562 40.3385 33.7142 40.5 30.752"
                stroke="currentColor" stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.5137 17.8359C20.5796 19.4484 21.0348 21.0209 21.8403 22.4193" stroke="currentColor"
                  stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.0879 37.072C12.6977 36.5753 13.3502 36.1336 14.0379 35.752" stroke="currentColor"
                  stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.5 60.7532C18.2028 60.7543 15.9442 60.1618 13.9434 59.0332" stroke="currentColor"
                  stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M40.5 44.0859H53.8333" stroke="currentColor" stroke-width="6.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M40.5 60.752H60.5C62.2681 60.752 63.9638 61.4543 65.214 62.7046C66.4643 63.9548 67.1667 65.6505 67.1667 67.4186V70.752"
                stroke="currentColor" stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M40.5 27.418H67.1667" stroke="currentColor" stroke-width="6.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M53.834 27.4186V17.4186C53.834 15.6505 54.5364 13.9548 55.7866 12.7046C57.0368 11.4543 58.7325 10.752 60.5007 10.752"
                stroke="currentColor" stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M53.8327 45.7513C54.7532 45.7513 55.4993 45.0051 55.4993 44.0846C55.4993 43.1642 54.7532 42.418 53.8327 42.418C52.9122 42.418 52.166 43.1642 52.166 44.0846C52.166 45.0051 52.9122 45.7513 53.8327 45.7513Z"
                stroke="currentColor" stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M60.5007 12.4193C61.4211 12.4193 62.1673 11.6731 62.1673 10.7526C62.1673 9.83213 61.4211 9.08594 60.5007 9.08594C59.5802 9.08594 58.834 9.83213 58.834 10.7526C58.834 11.6731 59.5802 12.4193 60.5007 12.4193Z"
                stroke="currentColor" stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M67.1667 72.4193C68.0871 72.4193 68.8333 71.6731 68.8333 70.7526C68.8333 69.8321 68.0871 69.0859 67.1667 69.0859C66.2462 69.0859 65.5 69.8321 65.5 70.7526C65.5 71.6731 66.2462 72.4193 67.1667 72.4193Z"
                stroke="currentColor" stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M67.1667 29.0853C68.0871 29.0853 68.8333 28.3391 68.8333 27.4186C68.8333 26.4981 68.0871 25.752 67.1667 25.752C66.2462 25.752 65.5 26.4981 65.5 27.4186C65.5 28.3391 66.2462 29.0853 67.1667 29.0853Z"
                stroke="currentColor" stroke-width="6.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default AIIcon;