import React, { useEffect, useState } from "react";
import style from "./previewAssignment.module.css";
import QuestionsItem from "../questionsItem";
import { Formik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthenticatedUser } from "../../redux/auth/auth.selectors";
import { useCreateStudentQuizSubmissionMutation } from "../../services/studentQuizSubmission.api";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { setNotification } from "../../redux/app/app.slice";

const PreviewAssignment = ({ assignments, isPreview = true }) => {
  const [initialValues, setInitialValues] = React.useState([]);
  const [createStudentSubmissionQuiz] =
    useCreateStudentQuizSubmissionMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const intl = useIntl();

  const user = useSelector(selectAuthenticatedUser);

  const [AssignmentSchema, setAssignmentSchema] = useState(
    Yup.object().shape({})
  );

  const __handleSubmit = async (values, { setSubmitting }) => {
    const assignmentsId = assignments.id;

    const formData = new FormData();

    formData.append("student", user.id);
    formData.append("coursework", assignmentsId);
    formData.append("status", "Submitted");

    // Transform questions to studentQuestionAnswers
    const studentQuestionAnswers = values.map((question) => {
      return {
        question: `/api/questions/${question.id}`,
        studentAnswer: question.studentAnswer,
        isCorrect: question.isCorrect,
        justification: question.justification || null,
        answer: question.answer || null,
      };
    });

    formData.append(
      "studentQuestionAnswers",
      JSON.stringify(studentQuestionAnswers)
    );

    const payload = {
      studentQuestionAnswers: studentQuestionAnswers,
      student: `/api/students/${user.id}`,
      coursework: `/api/courseworks/${assignmentsId}`,
      status: "Submitted",
    };
    const { data, error } = await createStudentSubmissionQuiz(payload);

    if (data) {
      dispatch(
        setNotification({
          type: "success",
          message: intl.formatMessage(
            {
              id: "notification.submit.coursework.success",
            }
          ),
        })
      );
    }
    if (error) {
      dispatch(
        setNotification({
          type: "error",
          message: intl.formatMessage(
            {
              id: "notification.submit.coursework.error",
            }
          ),
        })
      );
    }
    setSubmitting(false);
  };

  //type :
  useEffect(() => {
    const mappedQuestions = assignments?.questions.map((item) => ({
      ...item,
      answerJustification: item?.isJustification && "",
      answer: item?.answer || null,
      justification: item?.justification || null,
      studentAnswer: item?.studentAnswer || [],
    }));
    setInitialValues(mappedQuestions);
  }, [assignments]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        {isPreview && (
          <button className={style.backButton} onClick={() => navigate(-1)}>
            <LeftOutlined />
          </button>
        )}
        <h2 className={style.title}>{assignments?.title}</h2>
      </div>
      {/*    <div className={style.containerAssignment}>
                <h5 className={style.introductionTitle}>
                    {intl.formatMessage({ id: "assignment.preview.introduction" })}
                </h5>
                <p className={style.introduction}>{assignments?.introduction}</p>
            </div> */}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AssignmentSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={__handleSubmit}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <div className={style.listQuistionsContainer}>
            {values?.map((question, indexQuestion) => (
              <QuestionsItem
                setFieldValue={isPreview ? undefined : setFieldValue}
                question={question}
                indexQuestion={indexQuestion}
                key={"Questions+" + indexQuestion}
              />
            ))}

            {!isPreview && (
              <div className={style.btnSubmitContainer}>
                <button
                  type="submit"
                  className={style.btnSubmit}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default PreviewAssignment;
