import {AppstoreAddOutlined, ProjectOutlined, SnippetsOutlined, FolderAddOutlined, DownOutlined} from "@ant-design/icons";
import './leftSideMenu.css';
import {useGetMeQuery} from "../../services/auth.api";
import {useSelector} from "react-redux";
import {selectClassroom} from "../../redux/app/app.selectors";
import { NavLink, useLocation } from "react-router-dom";
import {useIntl} from "react-intl";

const LeftSideMenu = ({collapsed, hideClass}) => {
    const { data: authenticatedUser, error, isLoading } = useGetMeQuery();
    const classroom = useSelector(selectClassroom);
    const intl = useIntl();
    const location = useLocation();
    let isTeacher = false;
    if(authenticatedUser){
        const roles = authenticatedUser.roles
        isTeacher = roles.includes('ROLE_TEACHER');
    }
    const isRootPath = location.pathname === '/';

    return (
        <div className="list">
            {(classroom && !hideClass) && <span className={`classroom-name ${ collapsed ? `collapsed`:``}`}>{collapsed ? classroom.name.charAt(0) : classroom.name}</span>}
            {isRootPath ?
                <NavLink caseSensitive to="/">
                    <button
                        type="button"
                        className={`list-btn ${ collapsed ? `collapsed`:``}`}
                    >
                        {
                            collapsed
                                ?
                                <SnippetsOutlined className="icon"/>
                                :
                                <>
                                    <FolderAddOutlined />
                                    <span>{intl.formatMessage({id: "menu.item.classes"})}</span>
                                </>
                        }
                    </button>
                </NavLink>
                :
                <>
                    <NavLink caseSensitive to="/">
                        <button
                            type="button"
                            className={`list-btn ${collapsed ? `collapsed` : ``}`}
                        >
                            {
                                collapsed
                                    ?
                                    <SnippetsOutlined className="icon"/>
                                    :
                                    <>
                                        <FolderAddOutlined/>
                                        <span>{intl.formatMessage({id: "menu.item.classes"})}</span>
                                    </>
                            }
                        </button>
                    </NavLink>

                    <NavLink caseSensitive to={`/coursework`}>
                        <button
                            type="button"
                            className={`list-btn ${collapsed ? `collapsed` : ``}`}
                        >
                            {
                                collapsed
                                    ?
                                    <SnippetsOutlined className="icon"/>
                                    :
                                    <>
                                        <SnippetsOutlined/>
                                        <span>{intl.formatMessage({id: "menu.item.coursework"})}</span>
                                    </>
                            }
                        </button>
                    </NavLink>
                    {isTeacher && (
                        <>
                            <NavLink caseSensitive to="/rubric">
                                <button
                                    type="button"
                                    className={`list-btn ${collapsed ? `collapsed` : ``}`}
                                >
                                    {
                                        collapsed
                                            ?
                                            <ProjectOutlined style={{rotate: "-90deg"}} className="icon"/>
                                            :
                                            <>
                                                <ProjectOutlined style={{rotate: "-90deg"}}/>
                                                <span>{intl.formatMessage({id: "menu.item.rubrics"})}</span>
                                            </>
                                    }
                                </button>
                            </NavLink>

                            <NavLink caseSensitive to="/submissions">
                                <button
                                    type="button"
                                    className={` list-btn ${collapsed ? `collapsed` : ``}`}
                                >
                                    {
                                        collapsed
                                            ?
                                            <AppstoreAddOutlined className="icon"/>
                                            :
                                            <>
                                                <AppstoreAddOutlined/>
                                                <span>{intl.formatMessage({id: "menu.item.submissions"})}</span>
                                            </>

                                    }
                                </button>
                            </NavLink>
                        </>
                    )}
                    {!isTeacher && (
                        <NavLink caseSensitive to="/student/submission">
                            <button
                                type="button"
                                className={`list-btn ${collapsed ? `collapsed` : ``}`}
                            >
                                {
                                    collapsed
                                        ?
                                        <AppstoreAddOutlined className="icon"/>
                                        :
                                        <>
                                            <AppstoreAddOutlined/>
                                            <span>{intl.formatMessage({id: "menu.item.submissions"})}</span>
                                        </>

                                }
                            </button>
                        </NavLink>
                    )}
                </>
            }
        </div>
    );
}

export default LeftSideMenu