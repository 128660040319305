
import styled from './errorMessage.module.css'

const ErrorMessage = ({ message }) => {
    if (!message) return null;

    return (
        <div className={styled.errorMessage}>
            {message}
        </div>
    );
};

export default ErrorMessage;