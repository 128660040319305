import { initialState } from './locale.slice';
import { createSelector } from '@reduxjs/toolkit';

const selectDomain = (state) => state.locale ?? initialState;

export const selectLocale = createSelector(
    [selectDomain],
    (domainState) => {
        return domainState.locale;
    }
);

