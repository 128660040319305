
export const encapsulateCourseWork  = (object2) =>{
    // Merging values from object2 into defaultInitialValues, including image handling
    console.log("&&&",object2)
    const defaultInitialValues = {
        title: "",
        type: "Custom",
        description: "",
        dueDate: null,
        state: "active",
        image: null,
        rubric: null,
        isPracticeMode: false,
        allowHandWriting: false,
        writingAssistance: false,
        allowUploadFile: false,
        questions: [
            {
                type: "MultiChoice",
                questionText: "",
                image: null,
                options: [
                    { text: "", isCorrect: false },
                    { text: "", isCorrect: false }
                ]
            },
            {
                type: "Essay",
                questionText: "",
                image: null,
                description: "",
            },
            {
                type: "ShortAnswer",
                questionText: "",
                image: null,
            },
            {
                type: "Custom",
                questionText: "",
                image: null,
            }
        ],
    };

    return {
        id:object2.id,
        title: object2.title || defaultInitialValues.title,
        type: object2.type || defaultInitialValues.type,
        description: object2.description || defaultInitialValues.description,
        dueDate: object2.dueDate || defaultInitialValues.dueDate,
        state: object2.state || defaultInitialValues.state,
        image: object2.image || defaultInitialValues.image,
        rubric: object2?.rubric?.id || defaultInitialValues.rubric,
        isPracticeMode: object2.isPracticeMode || defaultInitialValues.isPracticeMode,
        allowHandWriting: object2.allowHandWriting || defaultInitialValues.allowHandWriting,
        writingAssistance: object2.writingAssistance || defaultInitialValues.writingAssistance,
        allowUploadFile: object2.allowUploadFile || defaultInitialValues.allowUploadFile,
        questions: object2.questions.map(question => {
            switch (question.type) {
                case "MultiChoice":
                    return {
                        type: "MultiChoice",
                        questionText: question.questionText || "",
                        isJustification: question.isJustification || false,
                        image: question.image ? {
                            filePath: question.image.filePath,
                            fileSize: question.image.fileSize,
                        } : null,  // Handle the image field
                        options: question.options.map(option => ({
                            text: option.text || "",
                            isCorrect: option.isCorrect || false
                        }))
                    };
                case "Essay":
                    return {
                        type: "Essay",
                        questionText: question.questionText || "",
                        description: question.questionText || "",
                        // Use questionText as description
                        rubric: question?.rubric?.["@id"] || null,
                        image: question.image ? {
                            filePath: question.image.filePath,
                            fileSize: question.image.fileSize,
                        } : null  // Handle the image field
                    };
                case "ShortAnswer":
                    return {
                        type: "ShortAnswer",
                        questionText: question.questionText || "",
                        image: question.image ? {
                            filePath: question.image.filePath,
                            fileSize: question.image.fileSize,
                        } : null  // Handle the image field
                    };
            }
        })
    };

}


export const encapsulateCourseWorkForSubmission  = (object2) =>{
    // Merging values from object2 into defaultInitialValues, including image handling
    const defaultInitialValues = {
        title: "",
        type: "Custom",
        description: "",
        dueDate: null,
        state: "active",
        image: null,
        rubric: null,
        isPracticeMode: false,
        allowHandWriting: false,
        aiAssistance: false,
        allowUploadFile: false,
        questions: [
            {
                type: "MultiChoice",
                questionText: "",
                image: null,
                options: [
                    { text: "", isCorrect: false },
                    { text: "", isCorrect: false }
                ]
            },
            {
                type: "Essay",
                questionText: "",
                image: null,
                description: "",
            },
            {
                type: "ShortAnswer",
                questionText: "",
                image: null,
            },
            {
                type: "Custom",
                questionText: "",
                image: null,
            }
        ],
    };

    return {

        id:object2.id,
        title: object2.title || defaultInitialValues.title,
        type: object2.type || defaultInitialValues.type,
        description: object2.description || defaultInitialValues.description,
        dueDate: object2.dueDate || defaultInitialValues.dueDate,
        state: object2.state || defaultInitialValues.state,
        image: object2.image || defaultInitialValues.image,
        rubric: object2?.rubric?.id || defaultInitialValues.rubric,
        isPracticeMode: object2.isPracticeMode || defaultInitialValues.isPracticeMode,
        allowHandWriting: object2.allowHandWriting || defaultInitialValues.allowHandWriting,
        aiAssistance: object2.aiAssistance || defaultInitialValues.aiAssistance,
        allowUploadFile: object2.allowUploadFile || defaultInitialValues.allowUploadFile,
        questions: object2.questions.map(question => {
            switch (question.type) {
                case "MultiChoice":
                    return {
                        type: "MultiChoice",
                        id:question.id,
                        questionText: question.questionText || "",
                        isJustification:question.isJustification || false,
                        image: question.image ? {
                            filePath: question.image.filePath,
                            fileSize: question.image.fileSize,
                        } : null,  // Handle the image field
                        options: question.options.map(option => ({
                            text: option.text || "",
                            isCorrect: option.isCorrect || false,
                            id:option.id
                        }))
                    };
                case "Essay":
                    return {
                        type: "Essay",
                        id:question.id,
                        isJustification:question.isJustification || false,
                        questionText: question.questionText || "",
                        description: question.questionText || "",
                        // Use questionText as description
                        rubric: question?.rubric?.["@id"] || null,
                        image: question.image ? {
                            filePath: question.image.filePath,
                            fileSize: question.image.fileSize,
                        } : null  // Handle the image field
                    };
                case "ShortAnswer":
                    return {
                        type: "ShortAnswer",
                        id:question.id,
                        isJustification:question.isJustification || false,
                        questionText: question.questionText || "",
                        image: question.image ? {
                            filePath: question.image.filePath,
                            fileSize: question.image.fileSize,
                        } : null  // Handle the image field
                    };
            }
        })
    };

}