

const RemoveIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1798 6.44727L5.53271 18.0943" stroke="#98A2B3" stroke-width="1.94118" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M5.53271 6.44727L17.1798 18.0943" stroke="#98A2B3" stroke-width="1.94118" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>

    )
}

export default RemoveIcon