import React from 'react';
import { CheckOutlined, FileOutlined } from '@ant-design/icons';
import styles from './submissionCard.module.css';
import AvatarStudent from "../../assets/icons/AvatarStudent";
import EditIcon from "../../assets/icons/EditIcon";
import {useIntl} from "react-intl";

export const SubmissionCard = ({ student, date, isScored, hasCorrected }) => {
    const intl = useIntl();
    return (
        <div className={styles.card}>
            <div className={styles.content}>
                <div className={styles.details}>
                    <div className={styles.infosBlock}>
                        <div className={styles.avatar}><AvatarStudent/></div>
                        <div className={styles.studentInfo}>
                            <span className={styles.name}>{student}</span>
                            {isScored && <span className={styles.scoreBadge}>Score</span>}
                        </div>
                    </div>
                    <div className={styles.dateLabel}>
                        {intl.formatMessage({id: 'student.submissions.card.date.submitted'})}
                    </div>
                    <div className={styles.date}>{date}</div>
                </div>
            </div>
            <div className={`${styles.correction} ${!hasCorrected && styles.correctionBlocPending}`}>
                {hasCorrected ? (
                    <>
                        <CheckOutlined className={styles.correctionIcon}/>
                        <span className={styles.correctionTextDone}><EditIcon/>
                            {intl.formatMessage({id: 'student.submissions.card.button.correction'})}
                        </span>
                    </>
                ) : (
                    <>
                        <span className={styles.correctionTextPending} onClick={() => console.log('clicked')}><EditIcon/>
                            {intl.formatMessage({id: 'student.submissions.card.button.correction'})}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};