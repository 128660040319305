import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

import { createReducer } from './reducers';
import { api } from '../services/api';

export function configureAppStore(preloadedState = {}) {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'app','locale'],
  };

  const persistedReducer = persistReducer(persistConfig, createReducer());
  const middlewares = [api.middleware];

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().prepend(middlewares),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
  });
  const persistor = persistStore(store);

  return { store, persistor };
}
