import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import AppLayout from "../Layout/AppLayout";
import { useIntl } from "react-intl";
import CreateAssignment from "../../Components/createAssignment";
import {
  useGetCourseWorkQuery,
  useLazyGetCourseWorkQuery,
} from "../../services/coursworks.api";
import { encapsulateCourseWork } from "../../Utils/encapsulateCourseWork";
import CustomSpin from "../../Components/customSpin/CustomSpin";

const NewAssignment = () => {
  const params = useParams();
  const mode = params.mode;

  const [dataFormated, setDataFormated] = useState(undefined);
  const [
    triggerCourseWorkById,
    { data: dataF, isLoading: isLoadingAssignments },
  ] = useLazyGetCourseWorkQuery();

  useEffect(() => {
    if (params.id) triggerCourseWorkById(params.id);
  }, []);

  useEffect(() => {
    if (dataF) {
      const { quiz, ...data } = dataF;
      const { questions } = quiz;
      setDataFormated(
        encapsulateCourseWork({
          ...data,
          questions,
        })
      );
    }
  }, [dataF]);

  if (isLoadingAssignments) {
    return <CustomSpin />;
  }

 

  return (
    <AppLayout hideHeader={true}>
      <CreateAssignment
        creationMode={mode}
        initialValues={dataFormated}
        isEditing={!!params?.id}
      />
    </AppLayout>
  );
};

export default NewAssignment;
