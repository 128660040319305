import { api } from './api';

export const studentQuizSubmission = api.injectEndpoints({
    endpoints: (builder) => ({
        createStudentQuizSubmission: builder.mutation({
            query: (formData ) => ({
                url: `/api/student-quiz-submissions`,
                method: 'POST',
                body: formData,
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'studentQuizSubmission', id: 'LIST' },
                        { type: 'studentQuizSubmission', id: 'LIST' },
                    ],
        }),
        checkIsSubmitted: builder.mutation({
            query: (formData) => ({
                url: '/api/check-is-submitted',
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags: [{type: 'Assignments', id: 'ALL'}],
        }),



    })
});

export const {useCreateStudentQuizSubmissionMutation, useCheckIsSubmittedMutation} = studentQuizSubmission;