

const  EditIcon = () => {

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 1.66602H7.50002C7.03978 1.66602 6.66669 2.03911 6.66669 2.49935V4.16602C6.66669 4.62625 7.03978 4.99935 7.50002 4.99935H12.5C12.9603 4.99935 13.3334 4.62625 13.3334 4.16602V2.49935C13.3334 2.03911 12.9603 1.66602 12.5 1.66602Z"
                stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M6.66665 3.33398H4.99998C4.55795 3.33398 4.13403 3.50958 3.82147 3.82214C3.50891 4.1347 3.33331 4.55862 3.33331 5.00065V16.6673C3.33331 17.1093 3.50891 17.5333 3.82147 17.8458C4.13403 18.1584 4.55795 18.334 4.99998 18.334H15C15.442 18.334 15.8659 18.1584 16.1785 17.8458C16.4911 17.5333 16.6666 17.1093 16.6666 16.6673V16.2507"
                stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M13.3333 3.33398H15C15.2923 3.33428 15.5793 3.41144 15.8323 3.5577C16.0854 3.70397 16.2955 3.9142 16.4416 4.16732"
                stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.66669 15H7.50002" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M17.8141 10.5218C18.1461 10.1898 18.3326 9.73959 18.3326 9.27012C18.3326 8.80066 18.1461 8.35042 17.8141 8.01846C17.4822 7.68649 17.0319 7.5 16.5625 7.5C16.093 7.5 15.6428 7.68649 15.3108 8.01846L11.9691 11.3618C11.771 11.5598 11.626 11.8046 11.5475 12.0735L10.85 14.4651C10.8291 14.5368 10.8278 14.6128 10.8463 14.6852C10.8649 14.7576 10.9025 14.8236 10.9554 14.8764C11.0082 14.9292 11.0742 14.9669 11.1466 14.9854C11.2189 15.004 11.2949 15.0027 11.3666 14.9818L13.7583 14.2843C14.0272 14.2058 14.272 14.0608 14.47 13.8626L17.8141 10.5218Z"
                stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default EditIcon;