import {Alert, Input} from "antd";
import "./signIn.css"
import {Formik} from "formik";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {useLazyGetMeQuery, useLoginMutation} from "../../services/auth.api";
import {logout, setCredentials} from "../../redux/auth/auth.slice";
import {useEffect, useState} from "react";
import {setClassroom, setLoading} from "../../redux/app/app.slice";
import {loginSchema} from "./form.schema";
import remediaLogo from "../../assets/images/remedia-logo.png";
import {InfoCircleOutlined} from "@ant-design/icons";
import {Spinner} from "@chakra-ui/react";
import {useIntl} from "react-intl";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const [login, { isLoading }] = useLoginMutation();
  const [trigger] = useLazyGetMeQuery();

  const [errorMessage, setErrorMessage] = useState(null);


    useEffect(() => {
        dispatch(logout())
        dispatch(setClassroom(null));
    }, []);
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);


  const handlePostLogin = async ({ token, refresh_token }) => {
    const {data:user, error} = await trigger();

    if(error){
       setErrorMessage("An error occurred during login")

    }
    if(user){
      dispatch(setCredentials({ token,refresh_token, user }));
      navigate('/');
    }
  };

  return (
      <main>
          <img src={remediaLogo} alt="remedia logo" className="__remediaLogo"/>
          <div className="container">
              <div className="login __box">
                  <h1 className="login__title __title">{intl.formatMessage({id: "login.title"})}</h1>
                  <h2 className="login__subTitle __mixSubTitle">{intl.formatMessage({id: "login.subTitle"})}</h2>
          <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={loginSchema}
              onSubmit={async (values, { setSubmitting }) => {
                const { data, error } = await login(values);

                if (error) {
                    setErrorMessage(intl.formatMessage({id: "login.form.message.error"}))
                    setSubmitting(false);
                }

                if (data) {
                  await handlePostLogin(data);
                }

              }}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="login__form">
                    {errorMessage && (
                        <Alert
                            message={errorMessage}
                            type="error"
                            icon={<InfoCircleOutlined />}
                            showIcon
                            className="__errorField"
                        />
                    )}
                  <Input
                      placeholder={intl.formatMessage({id: "login.form.email.placeholder"})}
                      size="large"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="login__form-email"
                  />
                  {errors.email && touched.email && errors.email}
                  <Link to="/password-resets" className="login__form-forgot">{intl.formatMessage({id: "login.form.link.forgotPassword"})}</Link>
                  <Input.Password
                      placeholder={intl.formatMessage({id: "login.form.password.placeholder"})}
                      type="password"
                      size="large"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className="login__form-password"
                  />
                  {errors.password && touched.password && errors.password}
                  <button type="submit" disabled={isSubmitting} className="primary login__form-btn __authBtn">
                      {isLoading ? <span>{intl.formatMessage({id: "login.form.button.signIn.text"})} <Spinner height={10} width={10}  /></span> : "Sign in"}
                  </button>
                </form>
            )}
          </Formik>
          <span className="login__otherOptions-register">
          {intl.formatMessage({id: "login.form.link.do.not.have.an.account"})} <Link to="/sign-up">{intl.formatMessage({id: "login.form.button.signUp.text"})}</Link>
          </span>
          </div>
              </div>
      </main>
  );
};

export default SignIn;
