import FilterIcon from "../../assets/images/filter.svg";
import { Button, Dropdown} from "antd";
import {useIntl} from "react-intl";


const Filter = ({ filterItems, selectedFilterItems, setSelectedFilterItems }) => {

    const intl = useIntl();
    const handleCheckboxChange = (e, key) => {
        const { checked } = e.target;
        setSelectedFilterItems((prev) =>
          checked ? [...prev, key] : prev.filter((item) => item !== key)
        );
    };

    const menu = filterItems.map((item) => ({
        key: item.key,
        label: (
          <label  key={item.key} onClick={(e) => e.stopPropagation()} >
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, item.key)}
              checked={selectedFilterItems.includes(item.key)}
            />
            {item.label} 
          </label>
        ),
    }));
    

    return (
        <>
          <Dropdown menu={{ items: menu }} trigger={["click"]}>
              <Button onClick={(e) => e.preventDefault()} className="filter-btn">
                <img src={FilterIcon} alt="filter icon" width="18px" />
                <span>{intl.formatMessage({id: "assignment.list.table.filter.title"})}</span>
              </Button>
          </Dropdown>
        </>
    );
};

export default Filter;
