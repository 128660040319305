import { useEffect, useState } from "react";
import styles from "./createAssignment.module.css";
import { Field, FieldArray, Form, Formik } from "formik";
import {
  Button,
  DatePicker,
  Modal,
  Popover,
  Select,
  Switch,
  Upload,
} from "antd";
import dayjs from "dayjs";
import {
  AlignLeftOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  PlusCircleFilled,
  PlusOutlined,
  SettingOutlined,
  UpOutlined,
} from "@ant-design/icons";
import ChatIcon from "../../assets/icons/ChatIcon";
import RemoveIcon from "../../assets/icons/RemoveIcon";
import MagicIcon from "../../assets/icons/MagicIcon";
import UploadFileIcon from "../../assets/icons/UploadFileIcon";
import SuffixIcon from "../../assets/icons/SuffixIcon";
import { useNavigate } from "react-router-dom";
import FileIcon from "../../assets/icons/FileIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import { useIntl } from "react-intl";
import CloudDownload from "../../assets/icons/CloudDownload";
import {
  useCreateMcqCourseworkMutation,
  useGetImageFromServerQuery,
  useLazyGetImageFromServerQuery,
  useUpdateCourseworkMutation,
} from "../../services/coursworks.api";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { selectClassroom } from "../../redux/app/app.selectors";
import { selectAuthenticatedUser } from "../../redux/auth/auth.selectors";
import { useLazyGetRubricsByTeacherQuery } from "../../services/rubrics.api";
import { SelectValue } from "antd/es/select";
import ErrorMessage from "../ErrorMessage";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/app/app.slice";

const { RangePicker } = DatePicker;

const { Dragger } = Upload;

const getInitialQuestion = (format) => {
  switch (format) {
    case "MultiChoice":
      return {
        type: "MultiChoice",
        context: null,
        isJustification: false,
        questionText: "",
        image: null,
        options: [
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
        ],
      };
    case "Essay":
      return {
        type: "Essay",
        context: null,
        description: "",
        image: null,
        rubric: null,
      };
    case "ShortAnswer":
      return {
        type: "ShortAnswer",
        description: "",
        image: null,
      };
    default:
      return {
        type: "MultiChoice",
        context: null,
        isJustification: false,
        image: null,
        questionText: "",
        options: [
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
        ],
      };
  }
};

const defaultInitialValues = {
  title: "",
  type: "Custom",
  description: "",
  dueDate: null,
  state: "active",
  image: null,
  rubric: null,
  isPracticeMode: false,
  allowHandWriting: false,
  writingAssistance: false,
  allowUploadFile: false,
  questions: [getInitialQuestion("Custom")],
};

const CreateAssignment = ({
  creationMode,
  initialValues = defaultInitialValues,
  isEditing = false,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [visible, setIsVisible] = useState(false);
  const [idAssignement, setIdAssignement] = useState(false);
  const dispatch = useDispatch();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [triggerCreateMcqCoursework, { data, isLoading: isLoadingCreate }] =
    useCreateMcqCourseworkMutation();
  const intl = useIntl();
  const classroom = useSelector(selectClassroom);
  const user = useSelector(selectAuthenticatedUser);
  const [triggerRubric, { data: rubricData, isLoading: isLoadingRubrics }] =
    useLazyGetRubricsByTeacherQuery();

  const [
    triggerUpdateMcqCoursework,
    { dataUpdate, isLoading: isLoadingUpdate },
  ] = useUpdateCourseworkMutation();
  const [triggerGetImage] = useLazyGetImageFromServerQuery();

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      triggerRubric({ teacherId: user.id });
    }
  }, [triggerRubric, user]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInKB = (file.size / 1024).toFixed(2); // Convert bytes to KB
      setUploadedFile({
        name: file.name,
        size:
          fileSizeInKB > 1024
            ? `${(fileSizeInKB / 1024).toFixed(2)} MB`
            : `${fileSizeInKB} KB`,
        url: URL.createObjectURL(file), // Create a URL for file preview
      });
    }
  };

  const propsFileContext = {
    name: "file",
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log("done");
      } else if (status === "error") {
        console.log("error");
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleRemoveFile = () => {
    if (uploadedFile?.url) {
      URL.revokeObjectURL(uploadedFile.url); // Clean up the object URL
    }
    setUploadedFile(null);
  };

  const handlePopoverVisibleChange = (visible) => {
    setIsPopoverOpen(visible);
  };

  const showModal = () => {
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleFormatChange = (setFieldValue, value) => {
    setFieldValue("type", value);
    setFieldValue("questions", [getInitialQuestion(value)]);
  };

  const handleQuestionTypeChange = (setFieldValue, index, value) => {
    setFieldValue(`questions.${index}.type`, value);
    if (value === "MultiChoice") {
      setFieldValue(`questions.${index}.options`, [
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
      ]);
    } else {
      // Remove options if switching to short answer
      setFieldValue(`questions.${index}.options`, undefined);
    }
  };

  const fetchImageBlob = async (filePath) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/image/${filePath}`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch image from path: ${filePath}`);
    }
    const blob = await response.blob();
    const fileName = filePath.split("/").pop(); // Extract file name from the path
    return new File([blob], fileName, { type: blob.type });
  };

  const appendFormData = async (formData, key, value) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else if (Array.isArray(value)) {
      for (const [index, item] of value.entries()) {
        await appendFormData(formData, `${key}[${index}]`, item);
      }
    } else if (value && typeof value === "object") {
      for (const subKey of Object.keys(value)) {
        const subValue = value[subKey];
        const fullKey = `${key}[${subKey}]`;

        // Check if this is an image with a filepath and process it (later)

        if (subValue?.["filePath"]) {
          try {
            value[subKey] = await fetchImageBlob(subValue["filePath"]); // Replace with the recreated File object
          } catch (error) {
            console.error(`Error processing image for ${fullKey}:`, error);
            subValue[subKey] = null; // Fallback to null on error
          }
        }

        await appendFormData(formData, fullKey, value[subKey]);
      }
    } else {
      formData.append(key, value);
    }
  };

  const processValues = async (formData, values) => {
    // Loop over values and process them without creating a new FormData
    for (const key of Object.keys(values)) {
      const newKey = key?.startsWith("questions")
        ? key.replace("questions", "quiz[questions]")
        : key;
      await appendFormData(formData, newKey, values[key]);
    }
  };
  const onHandleSubmit = async (values) => {
    const formData = new FormData();
    await processValues(formData, values);

    for (const key of formData.keys()) {
      if (key.startsWith("classrooms[0]") || key.startsWith("teacher")) {
        formData.delete(key);
      }
    }

    formData.append("grade", classroom.grade);
    formData.append("subject", classroom.subject);

    if (isEditing) {
      formData.append("teacher", classroom?.teacher["@id"]);
      formData.append("classroom", classroom["@id"]);

      formData.delete("id");
      formData.append("id-real", values.id);
      setIdAssignement(values.id);
      const { data, error } = await triggerUpdateMcqCoursework({
        formData: formData,
      });
      if (data) {
        showModal();
        dispatch(
          setNotification({
            type: "success",
            message: intl.formatMessage(
              {
                id: "notification.edit.message.success",
              },
              {
                type: "Assignment",
              }
            ),
          })
        );
      }
      if (error) {
        dispatch(
          setNotification({
            type: "error",
            message: intl.formatMessage(
              {
                id: "notification.edit.message.error",
              },
              {
                type: "Assignment",
              }
            ),
          })
        );
      }
    } else {
      formData.append("teacher", classroom?.teacher["@id"]);
      formData.append("classroom", classroom["@id"]);

      const { data, error } = await triggerCreateMcqCoursework({
        formData: formData,
      });

      if (data) {
        setIdAssignement(data.id);
        dispatch(
          setNotification({
            type: "success",
            message: intl.formatMessage(
              {
                id: "notification.create.message.success",
              },
              {
                type: "Assignment",
              }
            ),
          })
        );
        showModal();
      }
      if (error) {
        dispatch(
          setNotification({
            type: "error",
            message: intl.formatMessage(
              {
                id: "notification.create.message.error",
              },
              {
                type: "Assignment",
              }
            ),
          })
        );
      }
    }
  };

  const handleFileUpload = async (fileUrl, index, formData) => {
    if (!fileUrl) return;

    try {
      // Fetch the file from the URL
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      // Convert the response into a Blob
      const fileBlob = await response.blob();
      const file = new File([fileBlob], "uploaded-file", {
        type: fileBlob.type,
      });

      // Create a FormData object and append the file
      formData.append(index, file);
    } catch (e) {
      console.log("error link image");
    }
  };

  const openRubrics = () => {
    setShowSelect(!showSelect);
  };

  const formatFileSize = (bytes) => {
    if (!bytes) return "0 KB";

    // Convert to KB first since the original size is in bytes
    const sizeInKB = bytes / 1024;

    // If size is greater than 1024 KB (1 MB)
    if (sizeInKB > 1024) {
      return `${(sizeInKB / 1024).toFixed(2)} MB`;
    }

    return `${sizeInKB.toFixed(2)} KB`;
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required*"),
    dueDate: Yup.date().required("Due date is required*"),
    type: Yup.string().required("Form type is required*"),
    questions: Yup.array().of(
      Yup.object().shape({
        type: Yup.string()
          .required("Question type is required*")
          .oneOf(["MultiChoice", "Essay", "ShortAnswer"], "Invalid question type*"),
        questionText: Yup.string().required("Question text is required*"),
        isJustification: Yup.boolean(),
        options: Yup.lazy((_, { type ,...obj}) => {            
          if (obj.originalValue?.type === "MultiChoice") {
            return Yup.array()
              .of(
                Yup.object().shape({
                  text: Yup.string().required("Option text is required*"),
                  isCorrect: Yup.boolean().required("Correctness is required*"),
                })
              )
              .min(2, "At least two options are required*");
          }
          return Yup.mixed().notRequired();
        }),
        rubric: Yup.lazy((_, { type ,...obj}) => {
          if (obj.originalValue?.type === "Essay") {
            return Yup.string().required("Rubric is required for Essay*");
          }
          return Yup.mixed().notRequired();
        }),
      })
    ),
  });
  

  console.log("validationSchema", validationSchema);

  return (
    <div className={styles.newAssignmentPage}>
      <div className={styles.newAssignmentContainer}>
        <div className={styles.newAssignmentContent}>
          <Formik
            initialValues={initialValues}
            onSubmit={onHandleSubmit}
            enableReinitialize
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              values,
              handleBlur,
              setFieldValue,
              onHandleSubmit,
              errors,
            }) => (
              <Form className={styles.newAssignmentForm}>
                <div className={styles.newAssignmentHeader}>
                  <div className={styles.newAssignmentTitle}>
                    <h1>
                      {intl.formatMessage({
                        id: "assignment.create.form.title",
                      })}
                    </h1>
                  </div>
                  {console.log("error", errors)}
                  <div className={styles.settingBlock}>
                    <Switch
                      checkedChildren={intl.formatMessage({
                        id: "assignment.create.form.setting.practice.mode.checked",
                      })}
                      unCheckedChildren={intl.formatMessage({
                        id: "assignment.create.form.setting.practice.mode.unchecked",
                      })}
                      checked={values.isPracticeMode}
                      onChange={(value) =>
                        setFieldValue("isPracticeMode", value)
                      }
                    />
                    <Popover
                      content={
                        <div className={styles.popoverMenu}>
                          <div className={styles.menuItem}>
                            {intl.formatMessage({
                              id: "assignment.create.form.setting.allow.handwritten",
                            })}
                            <Switch
                              checked={values.allowHandWriting}
                              onChange={(value) =>
                                setFieldValue("allowHandWriting", value)
                              }
                            />
                          </div>
                          <div className={styles.menuItem}>
                            {intl.formatMessage({
                              id: "assignment.create.form.setting.provide.ai.assistance",
                            })}
                            <Switch
                              checked={values.writingAssistance}
                              onChange={(value) =>
                                setFieldValue("writingAssistance", value)
                              }
                            />
                          </div>
                          <div className={styles.menuItem}>
                            {intl.formatMessage({
                              id: "assignment.create.form.setting.allow.upload.file",
                            })}
                            <Switch
                              checked={values.allowUploadFile}
                              onChange={(value) =>
                                setFieldValue("allowUploadFile", value)
                              }
                            />
                          </div>
                        </div>
                      }
                      trigger="click"
                      placement="bottomRight"
                      visible={isPopoverOpen}
                      onVisibleChange={handlePopoverVisibleChange}
                    >
                      <Button className={styles.popoverButton}>
                        <div className={styles.buttonContent}>
                          <SettingOutlined />{" "}
                          {intl.formatMessage({
                            id: "assignment.create.form.setting.button.text",
                          })}
                          {isPopoverOpen ? (
                            <UpOutlined className={styles.arrowIcon} />
                          ) : (
                            <DownOutlined className={styles.arrowIcon} />
                          )}
                        </div>
                      </Button>
                    </Popover>
                  </div>
                </div>
                {/* Basic Fields remain the same */}
                <div className={styles.formField}>
                  <label>
                    {intl.formatMessage({
                      id: "assignment.create.form.field.label.title",
                    })}
                  </label>
                  <div>
                    <Field
                      name={"title"}
                      placeholder={intl.formatMessage({
                        id: "assignment.create.form.field.title.placeholder",
                      })}
                      className={styles.formFieldInput}
                    />

                    {errors.title && <ErrorMessage message={errors.title} />}
                  </div>
                </div>
                {creationMode !== "ai" && (
                  <>
                    {/** Due date **/}
                    <div className={styles.dueDate}>
                      <label className="assignmentFields-title">
                        {intl.formatMessage({
                          id: "assignment.create.form.field.label.dueDate",
                        })}
                      </label>
                      <div>
                        <DatePicker
                          showTime={{ format: "HH:mm" }}
                          format="YYYY-MM-DD HH:mm"
                          placeholder={intl.formatMessage({
                            id: "assignment.create.form.field.dueDate.placeholder",
                          })}
                          value={values.dueDate ? dayjs(values.dueDate) : null}
                          onChange={(date) => {
                            setFieldValue(
                              "dueDate",
                              date ? date.toISOString() : null
                            );
                          }}
                          style={{ height: "44px", width: "512px" }}
                        />
                        {errors.dueDate && (
                          <ErrorMessage message={errors.dueDate} />
                        )}
                      </div>
                    </div>

                    {/* ... other basic fields ... */}
                    <div className={styles.formField}>
                      <label>
                        {intl.formatMessage({
                          id: "assignment.create.form.field.label.format",
                        })}
                      </label>
                      <Select
                        value={values.type}
                        className={styles.formFieldInputSelect}
                        onChange={(value) =>
                          handleFormatChange(setFieldValue, value)
                        }
                        suffixIcon={<SuffixIcon />}
                        options={[
                          {
                            value: "Custom",
                            label: intl.formatMessage({
                              id: "assignment.create.form.field.format.option.label.custom",
                            }),
                          },
                          {
                            value: "MCQ",
                            label: intl.formatMessage({
                              id: "assignment.create.form.field.format.option.label.mcq",
                            }),
                          },
                          {
                            value: "Essay",
                            label: intl.formatMessage({
                              id: "assignment.create.form.field.format.option.label.essay",
                            }),
                          },
                          {
                            value: "ShortAnswer",
                            label: intl.formatMessage({
                              id: "assignment.create.form.field.format.option.label.shortAnswer",
                            }),
                          },
                        ]}
                      />
                    </div>

                    {/* ... state ... */}
                    <div className={styles.formField}>
                      <label>
                        {intl.formatMessage({
                          id: "assignment.create.form.field.label.state",
                        })}
                      </label>
                      <Select
                        value={values.state}
                        className={styles.formFieldInputSelect}
                        name="state"
                        onChange={(value) => setFieldValue("state", value)}
                        suffixIcon={<SuffixIcon />}
                        options={[
                          {
                            value: "active",
                            label: intl.formatMessage({
                              id: "assignment.create.form.field.state.option.active",
                            }),
                          },
                          {
                            value: "draft",
                            label: intl.formatMessage({
                              id: "assignment.create.form.field.state.option.draft",
                            }),
                          },
                        ]}
                      />
                    </div>

                    <FieldArray name="questions">
                      {({ insert, remove, push }) => (
                        <div>
                          {values.questions.map((question, index) => (
                            <div className={styles.questionBlock} key={index}>
                              <div className={styles.questionHeader}>
                                {values.format !== "Essay" && (
                                  <h3>
                                    {intl.formatMessage({
                                      id: "assignment.create.form.questions.header.index",
                                    })}
                                    {index + 1}
                                  </h3>
                                )}

                                {values.type !== "Essay" && (
                                  <div className={styles.headerBlockBtn}>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        insert(
                                          index + 1,
                                          getInitialQuestion(
                                            values.questions[index].type
                                          )
                                        );
                                      }}
                                      className={styles.addQuestion}
                                    >
                                      <PlusOutlined />{" "}
                                      {intl.formatMessage({
                                        id: "assignment.create.form.questions.header.button.addQuestion",
                                      })}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        if (values.questions.length > 1) {
                                          remove(index);
                                        }
                                      }}
                                      className={styles.removeQuestion}
                                    >
                                      <DeleteOutlined />
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className={styles.questionsBlockContent}>
                                {/* Show type selector for custom and MCQ formats */}
                                {values.type === "Custom" && (
                                  <div className={styles.questionTypeBlock}>
                                    <div className={styles.questionType}>
                                      <label>
                                        {intl.formatMessage({
                                          id: "assignment.create.form.questions.field.label.type",
                                        })}
                                      </label>
                                      <Field
                                        as="select"
                                        name={`questions.${index}.type`}
                                        className={styles.selectField}
                                        onChange={(e) =>
                                          handleQuestionTypeChange(
                                            setFieldValue,
                                            index,
                                            e.target.value
                                          )
                                        }
                                      >
                                        {values.type === "Custom" && (
                                          <>
                                            <option value="MultiChoice">
                                              {intl.formatMessage({
                                                id: "assignment.create.form.questions.field.type.option.multiChoice",
                                              })}
                                            </option>
                                            <option value="ShortAnswer">
                                              {intl.formatMessage({
                                                id: "assignment.create.form.questions.field.type.option.shortAnswer",
                                              })}
                                            </option>
                                            <option value="Essay">
                                              {intl.formatMessage({
                                                id: "assignment.create.form.questions.field.type.option.essay",
                                              })}
                                            </option>
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                )}

                                <div className={styles.contextToggle}>
                                  <div className={styles.contextMenuIcon}>
                                    <AlignLeftOutlined />
                                  </div>
                                  <span className={styles.contextLabel}>
                                    {intl.formatMessage({
                                      id: "assignment.create.form.questions.field.label.context",
                                    })}
                                  </span>
                                  <Switch
                                    checked={
                                      question?.context !== null &&
                                      question?.context !== undefined
                                    }
                                    onChange={(value) =>
                                      setFieldValue(
                                        `questions.${index}.context`,
                                        question?.context !== null &&
                                          question?.context !== undefined
                                          ? null
                                          : ""
                                      )
                                    }
                                  />
                                </div>

                                {question?.context !== null &&
                                  question?.context !== undefined && (
                                    <div className={styles.contextInput}>
                                      <div>
                                        <Field
                                          as="textarea"
                                          name={`questions.${index}.context`}
                                          placeholder={intl.formatMessage({
                                            id: "assignment.create.form.questions.field.context.contentText.placeholder",
                                          })}
                                          className={styles.textAreaInput}
                                        />
                                        {errors?.questions?.[index]
                                          ?.context && (
                                          <ErrorMessage
                                            message={
                                              errors?.questions?.[index]
                                                ?.context
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}

                                {(values.type === "Custom" &&
                                  question.type === "Essay") ||
                                values.type === "Essay" ? (
                                  <>
                                    <div className={styles.rubricBlock}>
                                      <label>
                                        {intl.formatMessage({
                                          id: "assignment.create.form.questions.field.label.rubric",
                                        })}
                                      </label>
                                      <Button
                                        htmlType="button"
                                        className={styles.rubricBtn}
                                        onClick={openRubrics}
                                      >
                                        <PlusCircleFilled />
                                        {intl.formatMessage({
                                          id: "assignment.create.form.questions.button.rubric.add",
                                        })}
                                      </Button>
                                      {(showSelect ||
                                        values?.questions?.[index]?.rubric) && (
                                        <Select
                                          showSearch
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                          className={styles.rubricSelect}
                                          style={{ marginTop: 12 }}
                                          placeholder={intl.formatMessage({
                                            id: "assignment.create.form.questions.field.rubric.placeholder",
                                          })}
                                          name={`questions.${index}.rubric`}
                                          onChange={(value) =>
                                            setFieldValue(
                                              `questions.${index}.rubric`,
                                              value
                                            )
                                          }
                                          value={values.questions[index].rubric}
                                        >
                                          {rubricData["hydra:member"].map(
                                            (rubric) => (
                                              <Select.Option
                                                key={rubric.id}
                                                value={rubric["@id"]}
                                              >
                                                {rubric.title}
                                              </Select.Option>
                                            )
                                          )}
                                        </Select>
                                      )}
                                      {errors?.questions?.[index]?.rubric && (
                                        <ErrorMessage
                                          message={
                                            errors?.questions?.[index]?.rubric
                                          }
                                        />
                                      )}
                                    </div>
                                    <div className={styles.questionText}>
                                      <label>
                                        {intl.formatMessage({
                                          id: "assignment.create.form.questions.field.label.instruction",
                                        })}
                                      </label>
                                      <div
                                        className={`${styles.magicBlock} ${styles.containerBlockTextAreaAndUpload}`}
                                      >
                                        <div
                                          className={
                                            styles.blockTextAreaAndUpload
                                          }
                                        >
                                          <div>
                                            <Field
                                              as="textarea"
                                              name={`questions.${index}.questionText`}
                                              placeholder={intl.formatMessage({
                                                id: "assignment.create.form.questions.field.instruction.placeholder",
                                              })}
                                              className={styles.textAreaInput}
                                            />
                                          </div>

                                          <div className={styles.uploadFile}>
                                            {/* Custom Styled Button */}
                                            <label
                                              htmlFor={`file-upload-${index}`}
                                            >
                                              <UploadFileIcon />
                                            </label>

                                            {/* Hidden File Input */}
                                            <input
                                              name={`questions.${index}.image`}
                                              accept="image/*"
                                              id={`file-upload-${index}`}
                                              type="file"
                                              style={{ display: "none" }}
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `questions.${index}.image`,
                                                  e.target?.files?.[0] || null
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <Button
                                          className={styles.magicButton}
                                          icon={<MagicIcon />}
                                        />
                                      </div>
                                      {errors.questions &&
                                        errors.questions[index] &&
                                        errors.questions[index]
                                          .questionText && (
                                          <ErrorMessage
                                            message={
                                              errors?.questions?.[index]
                                                ?.questionText
                                            }
                                          />
                                        )}
                                    </div>
                                    {/* Display Uploaded File Name */}
                                    {values?.questions?.[index]?.image && (
                                      <div className={styles.blockUploadFiles}>
                                        <div className={styles.fileContent}>
                                          <div className={styles.iconFile}>
                                            <FileIcon
                                              className={styles.fileIcon}
                                            />
                                          </div>
                                          <div className={styles.blockFileInfo}>
                                            <span>
                                              {values?.questions?.[index]?.image
                                                ?.name ||
                                                values?.questions?.[index]
                                                  ?.image?.filePath}
                                            </span>
                                            <span>
                                              {formatFileSize(
                                                values?.questions?.[index]
                                                  ?.image?.size ||
                                                  values?.questions?.[index]
                                                    ?.image?.fileSize
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                        <button
                                          type={"button"}
                                          onClick={() => {
                                            setFieldValue(
                                              `questions.${index}.image`,
                                              null
                                            );
                                          }}
                                        >
                                          <TrashIcon />
                                        </button>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  //                     ) :values.type === "Essay" &&
                                  //                         question.type === "Essay" ?(
                                  //                         <>
                                  //                             gggggggggggggggg
                                  //                             <div className={styles.rubricBlock}>
                                  //                                 <label>
                                  //                                     {intl.formatMessage({
                                  //                                         id: "assignment.create.form.questions.field.label.rubric",
                                  //                                     })}
                                  //                                 </label>
                                  //                                 <Button
                                  //                                     htmlType="button"
                                  //                                     className={styles.rubricBtn}
                                  //                                     onClick={openRubrics}
                                  //                                 >
                                  //                                     <PlusCircleFilled />
                                  //                                     {intl.formatMessage({
                                  //                                         id: "assignment.create.form.questions.button.rubric.add",
                                  //                                     })}
                                  //                                 </Button>
                                  //                                 {showSelect && (
                                  //                                     <Select
                                  //                                         showSearch
                                  //                                         optionFilterProp="children"
                                  //                                         filterOption={(input, option) =>
                                  //                                             option.children
                                  //                                                 .toLowerCase()
                                  //                                                 .indexOf(input.toLowerCase()) >= 0
                                  //                                         }
                                  //                                         className={styles.rubricSelect}
                                  //                                         style={{ marginTop: 12 }}
                                  //                                         placeholder={intl.formatMessage({
                                  //                                             id: "assignment.create.form.questions.field.rubric.placeholder",
                                  //                                         })}
                                  //                                         name="rubric"
                                  //                                     >
                                  //                                         {rubricData["hydra:member"].map((rubric) => (
                                  //                                             <Select.Option key={rubric.id} value={rubric.id}>
                                  //                                                 {rubric.title}
                                  //                                             </Select.Option>
                                  //                                         ))}
                                  //                                     </Select>
                                  //                                 )}
                                  //                             </div>
                                  //                             <div className={styles.questionText}>
                                  //                                 <label>
                                  //                                     {intl.formatMessage({
                                  //                                         id: "assignment.create.form.questions.field.label.instruction",
                                  //                                     })}
                                  //                                 </label>
                                  //                                 <div
                                  //                                     className={`${styles.magicBlock} ${styles.containerBlockTextAreaAndUpload}`}
                                  //                                 >
                                  //                                     <div
                                  //                                         className={
                                  //                                             styles.blockTextAreaAndUpload
                                  //                                         }
                                  //                                     >
                                  //                                         <Field
                                  //                                             as="textarea"
                                  //                                             name={`description`}
                                  //                                             placeholder={intl.formatMessage({
                                  //                                                 id: "assignment.create.form.questions.field.instruction.placeholder",
                                  //                                             })}
                                  //                                             className={styles.textAreaInput}
                                  //                                         />
                                  //                                         <div className={styles.uploadFile}>
                                  //                                             {/* Custom Styled Button */}
                                  //                                             <label
                                  //                                                 htmlFor={`file-upload-${index}`}
                                  //                                             >
                                  //                                                 <UploadFileIcon />
                                  //                                             </label>
                                  //
                                  //                                             {/* Hidden File Input */}
                                  //                                             <input
                                  //                                                 name={`image`}
                                  //                                                 accept="image/*"
                                  //                                                 id={`file-upload-${index}`}
                                  //                                                 type="file"
                                  //                                                 style={{ display: "none" }}
                                  //                                                 onChange={(e) =>
                                  //                                                     setFieldValue(
                                  //                                                         `image`,
                                  //                                                         e.target?.files?.[0] || null
                                  //                                                     )
                                  //                                                 }
                                  //                                             />
                                  //                                         </div>
                                  //                                     </div>
                                  //                                     <Button
                                  //                                         className={styles.magicButton}
                                  //                                         icon={<MagicIcon />}
                                  //                                     />
                                  //                                 </div>
                                  //                             </div>
                                  //                             {/* Display Uploaded File Name */}
                                  //                             {values?.image && (
                                  //                                 <div className={styles.blockUploadFiles}>
                                  //                                     <div className={styles.fileContent}>
                                  //                                         <div className={styles.iconFile}>
                                  //                                             <FileIcon
                                  //                                                 className={styles.fileIcon}
                                  //                                             />
                                  //                                         </div>
                                  //                                         <div className={styles.blockFileInfo}>
                                  // <span>
                                  //   {
                                  //       values?.image?.name || values?.image?.filePath
                                  //   }
                                  // </span>
                                  //                                             <span>
                                  //   {formatFileSize(
                                  //       values?.image?.size || values?.image?.fileSize
                                  //   )}
                                  // </span>
                                  //
                                  //
                                  //                                         </div>
                                  //                                     </div>
                                  //                                     <button
                                  //                                         type={"button"}
                                  //                                         onClick={() => {
                                  //                                             setFieldValue(
                                  //                                                 `image`,
                                  //                                                 null
                                  //                                             );
                                  //                                         }}
                                  //                                     >
                                  //                                         <TrashIcon />
                                  //                                     </button>
                                  //                                 </div>
                                  //                             )}
                                  //                         </>
                                  //                     )
                                  <>
                                    <div className={styles.questionText}>
                                      <label>
                                        {intl.formatMessage({
                                          id: "assignment.create.form.questions.field.label.question",
                                        })}
                                      </label>
                                      <div className={styles.magicBlockInput}>
                                        <div
                                          className={styles.blockTextAndUpload}
                                        >
                                          <Field
                                            name={`questions.${index}.questionText`}
                                            placeholder={intl.formatMessage({
                                              id: "assignment.create.form.questions.field.question.text.placeholder",
                                            })}
                                            className={styles.textInput}
                                          />
                                          <div
                                            className={
                                              styles.uploadFileInTextInput
                                            }
                                          >
                                            {/* Custom Styled Button */}
                                            <label
                                              htmlFor={`file-upload-${index}`}
                                            >
                                              <UploadFileIcon />
                                            </label>

                                            {/* Hidden File Input */}
                                            <input
                                              name={`questions.${index}.image`}
                                              accept="image/*"
                                              id={`file-upload-${index}`}
                                              type="file"
                                              style={{ display: "none" }}
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `questions.${index}.image`,
                                                  e.target.files?.[0] || null
                                                )
                                              }
                                            />
                                          </div>
                                        </div>

                                        <Button
                                          className={styles.magicButton}
                                          icon={<MagicIcon />}
                                        />
                                      </div>
                                      {errors?.questions?.[index]
                                        ?.questionText && (
                                        <ErrorMessage
                                          message={
                                            errors?.questions?.[index]
                                              ?.questionText
                                          }
                                        />
                                      )}
                                      {/* Display Uploaded File Name */}
                                      {values?.questions?.[index]?.image && (
                                        <div
                                          className={styles.blockUploadFiles}
                                          style={{ marginTop: "10px" }}
                                        >
                                          <div className={styles.fileContent}>
                                            <div className={styles.iconFile}>
                                              <FileIcon
                                                className={styles.fileIcon}
                                              />
                                            </div>
                                            <div
                                              className={styles.blockFileInfo}
                                            >
                                              <span>
                                                {values?.questions?.[index]
                                                  ?.image?.name ||
                                                  values?.questions?.[index]
                                                    ?.image?.filePath}
                                              </span>
                                              <span>
                                                {formatFileSize(
                                                  values?.questions?.[index]
                                                    ?.image?.size ||
                                                    values?.questions?.[index]
                                                      ?.image?.fileSize
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <button
                                            type={"button"}
                                            onClick={() => {
                                              setFieldValue(
                                                `questions.${index}.image`,
                                                null
                                              );
                                            }}
                                          >
                                            <TrashIcon />
                                          </button>
                                        </div>
                                      )}
                                    </div>

                                    {question.type === "MultiChoice" && (
                                      <>
                                        <div
                                          className={styles.justificationToggle}
                                        >
                                          <div
                                            className={styles.contextMenuIcon}
                                          >
                                            <ChatIcon />
                                          </div>
                                          <span className={styles.contextLabel}>
                                            {intl.formatMessage({
                                              id: "assignment.create.form.questions.field.label.question.justification",
                                            })}
                                          </span>
                                          <Switch
                                            checked={question.isJustification}
                                            onChange={(value) =>
                                              setFieldValue(
                                                `questions.${index}.isJustification`,
                                                value
                                              )
                                            }
                                          />
                                        </div>

                                        <FieldArray
                                          name={`questions.${index}.options`}
                                        >
                                          {({
                                            push: pushOption,
                                            remove: removeOption,
                                          }) => (
                                            <div
                                              className={
                                                styles.optionsContainer
                                              }
                                            >
                                              {question.options?.map(
                                                (option, optionIndex) => (
                                                  <>
                                                    {errors?.questions?.[index]
                                                      ?.options?.[optionIndex]
                                                      ?.text && (
                                                      <ErrorMessage
                                                        message={
                                                          errors?.questions?.[
                                                            index
                                                          ]?.options?.[
                                                            optionIndex
                                                          ]?.text
                                                        }
                                                      />
                                                    )}
                                                    <div
                                                      className={
                                                        styles.optionRow
                                                      }
                                                      key={optionIndex}
                                                    >
                                                      <span
                                                        className={
                                                          styles.optionIndex
                                                        }
                                                      >
                                                        {String.fromCharCode(
                                                          65 + optionIndex
                                                        )}
                                                      </span>
                                                      <Field
                                                        name={`questions.${index}.options.${optionIndex}.text`}
                                                        placeholder={`${intl.formatMessage(
                                                          {
                                                            id: "assignment.create.form.questions.field.question.options",
                                                          }
                                                        )} ${String.fromCharCode(
                                                          65 + optionIndex
                                                        )}`}
                                                        className={
                                                          styles.textInput
                                                        }
                                                      />
                                                      <div
                                                        className={
                                                          styles.questionIsCorrect
                                                        }
                                                      >
                                                        <Field
                                                          as="select"
                                                          name={`questions.${index}.options.${optionIndex}.isCorrect`}
                                                          className={
                                                            styles.selectField
                                                          }
                                                          value={
                                                            question?.options?.[
                                                              optionIndex
                                                            ]?.isCorrect
                                                          }
                                                        >
                                                          <option value="false">
                                                            {intl.formatMessage(
                                                              {
                                                                id: "assignment.create.form.questions.field.question.options.incorrect",
                                                              }
                                                            )}
                                                          </option>
                                                          <option value="true">
                                                            {intl.formatMessage(
                                                              {
                                                                id: "assignment.create.form.questions.field.question.options.correct",
                                                              }
                                                            )}
                                                          </option>
                                                        </Field>
                                                      </div>
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          removeOption(
                                                            optionIndex
                                                          )
                                                        }
                                                        className={
                                                          styles.removeOption
                                                        }
                                                      >
                                                        <RemoveIcon />
                                                      </button>
                                                    </div>
                                                  </>
                                                )
                                              )}
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  pushOption({
                                                    text: "",
                                                    isCorrect: false,
                                                  })
                                                }
                                                className={styles.addOption}
                                              >
                                                <PlusOutlined
                                                  className={styles.plusIcon}
                                                />
                                                {intl.formatMessage({
                                                  id: "assignment.create.form.questions.field.question.options.button.add",
                                                })}
                                              </button>
                                            </div>
                                          )}
                                        </FieldArray>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </>
                )}
                {creationMode === "ai" && (
                  <>
                    {/** Due date **/}
                    <div className={styles.dueDate}>
                      <label className="assignmentFields-title">
                        {intl.formatMessage({
                          id: "assignment.create.form.field.label.dueDate",
                        })}
                      </label>
                      <DatePicker
                        showTime={{ format: "HH:mm" }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder={intl.formatMessage({
                          id: "assignment.create.form.field.dueDate.placeholder",
                        })}
                        value={values.dueDate ? dayjs(values.dueDate) : null}
                        onChange={(date) => {
                          setFieldValue(
                            "dueDate",
                            date ? date.toISOString() : null
                          );
                        }}
                        style={{ height: "44px", width: "512px" }}
                      />
                    </div>

                    {/** objective */}
                    <div className={styles.objectiveBlock}>
                      <label>
                        {intl.formatMessage({
                          id: "assignment.create.form.field.label.objective",
                        })}
                      </label>
                      <div className={styles.objectiveFileContainer}>
                        <Field
                          as="textarea"
                          name={"objective"}
                          placeholder={intl.formatMessage({
                            id: "assignment.create.form.field.objective.placeholder",
                          })}
                          className={styles.textAreaObjective}
                        />

                        <div className={styles.uploadFileInObjective}>
                          {/* Custom Styled Button */}
                          <label htmlFor={`objective`}>
                            <UploadFileIcon />
                          </label>

                          {/* Hidden File Input */}
                          <input
                            name={`objectiveUploadFile`}
                            id={`objective`}
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              setFieldValue(
                                `objectiveUploadFile`,
                                e.target.files
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/** context file **/}
                    <div className={styles.uploadFileContextBlock}>
                      <label>
                        {intl.formatMessage({
                          id: "assignment.create.form.field.label.contextFile",
                        })}
                      </label>
                      <Dragger
                        {...propsFileContext}
                        className={styles.uploadFileContext}
                      >
                        <div
                          className={`ant-upload-drag-icon ${styles.fileCloudUpload}`}
                        >
                          <CloudDownload className={styles.iconCloudUpload} />
                        </div>
                        <p className={styles.uploadTextContent}>
                          <b>
                            {intl.formatMessage({
                              id: "assignment.create.form.field.label.clickHerToUpload",
                            })}
                          </b>
                          {intl.formatMessage({
                            id: "assignment.create.form.field.label.dragAndDropText",
                          })}
                        </p>
                      </Dragger>
                    </div>
                  </>
                )}
                <div className={styles.blockBtn}>
                  <button type={"submit"} className={styles.draftButton}>
                    {intl.formatMessage({ id: "button.draft" })}
                  </button>
                  <button type="submit" className={styles.submitButton}>
                    {creationMode !== "ai"
                      ? intl.formatMessage({ id: "button.save" })
                      : intl.formatMessage({ id: "button.generate" })}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Modal
        visible={visible}
        footer={null}
        closable={false}
        centered
        className={styles.modal}
        onCancel={handleCancel}
      >
        <div className={styles.modalContent}>
          <div>
            <div className={styles.iconWrapper}>
              <CheckCircleOutlined className={styles.icon} />
            </div>
          </div>
          <div>
            <div className={styles.title}>Successful</div>
            <div className={styles.message}>Your changes have been saved.</div>
          </div>
        </div>

        <div className={styles.footer}>
          <a href="#">Library</a> &nbsp;|&nbsp;{" "}
          <button
            onClick={() => {
              navigate(`/assignment/preview/${idAssignement}`);
            }}
          >
            Preview
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateAssignment;
