import AppLayout from "../Layout/AppLayout";
import Table from "../../Components/table/Table";
import "./submissions.css"
import React, {useEffect, useState} from "react"
import {Badge, Button, Input} from "antd";
import {DownOutlined, FormOutlined, SearchOutlined, UpOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import {selectClassroom} from "../../redux/app/app.selectors";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import {
  useLazyGetStudentCourseWorkByClassroomQuery,
} from "../../services/studentcourseworks.api";
import {setNotification} from "../../redux/app/app.slice";
import {useIntl} from "react-intl";
import DocIcon from "../../assets/icons/DocIcon";
import InsightIcon from "../../assets/icons/InsightIcon";

const Submissions = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const [dataSorted, setDataSorted] = useState([]);
  const classroom = useSelector(selectClassroom);
  const [trigger, {data: studentAssignments, isLoading:isLoading }] = useLazyGetStudentCourseWorkByClassroomQuery();
  const dispatch = useDispatch()
  const intl = useIntl();
  useEffect(() => {
   // if (studentAssignments){
    //formtedData(studentAssignments)}

  }, [studentAssignments]);

  useEffect(() => {
    trigger(classroom.id);


  }, []);

  useEffect(() => {
    if (studentAssignments)
      setDataSorted(formtedData(studentAssignments["hydra:member"]))
  }, [studentAssignments]);

  console.log("    console.log(studentAssignments)\n",dataSorted)


  // useEffect(() => {
  //   if (!sortConfig){
  //     setDataSorted(formtedData(studentCourseWorks));
  //     console.log('studentCourseWorkssss', studentCourseWorks);
  //   }
  //   else {
  //     const studentSubmissionsData = formtedData(studentCourseWorks) || [];
  //     const sortedData = [...studentSubmissionsData]?.sort((a, b) => {
  //       if (sortConfig.direction === 'ascending') {
  //         return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
  //       }
  //       if(sortConfig.direction === 'descending'){
  //         return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
  //       }
  //       return 0;
  //     })
  //     setDataSorted(sortedData)
  //   }
  // }, [sortConfig, studentCourseWorks]);







  const timeConverter = (rawDate) => {
    return moment(rawDate).format("MMM DD YYYY hh:mm");
  }


   const formtedData = (data)=>(
       data?.map(item=>({
         id: item.id,
         first_name : item.student.family_name,
         title: item.coursework.title ,
         type: item.coursework.type,
         subject: item.coursework.subject ,
         state: item.studentSubmission? item.studentSubmission.state:"Started" ,
         created_at: item.coursework.created_at,
       }))
   )


  const columns = [
    { key: 1 , title: 'ID', dataIndex: 'id'},
    {
      key: 2,
      title: intl.formatMessage({id: "student.submissions.table.header.student.name.label"}),
      dataIndex:  "first_name",
    },
    { key: 3 , title: intl.formatMessage({id: "student.submissions.table.header.coursework.title.label"}), dataIndex: "title"},
    { key: 4 , title: intl.formatMessage({id: "student.submissions.table.header.format.label"}), dataIndex:  "type"},
    { key: 5 , title: intl.formatMessage({id: "student.submissions.table.header.subject.label"}), dataIndex: "subject"},
    { key: 6 , title: intl.formatMessage({id: "student.submissions.table.header.state.label"}), dataIndex: 'state' },
    {
      key: 7,
      title: intl.formatMessage({id: "student.submissions.table.header.date.submitted.label"}),
      dataIndex: 'created_at',
      render: (created_at) => timeConverter(created_at),

    },
    { key: 8 , title: '', dataIndex: 'countSubmissions'},
    { key: 9 , title: '', dataIndex: 'insight'},
  ];


  const navigate = useNavigate();

  const handleActionClick = (item) => {

    // lien temporaire:
    navigate('/students/submissions')

    if (item.state === "Pre-graded" || item.state === "Graded"){
      navigate(`/submission/${item.id}/show`);

    }
    else if (item.state === "Started" || item.state === "Submitted" ){
      dispatch(setNotification(
          {
            message: intl.formatMessage({id: "student.submissions.message.info.inProgress"}),
            type: 'info'
          }
          )
      );
    }
    else if (item.state === "Failed"){
      dispatch(setNotification(
          {
            message: intl.formatMessage({id: "student.submissions.message.error.failed"}),
            type: 'error'
          }
          )
      );

    }
    else {
      dispatch(setNotification(
          {
            message: intl.formatMessage({id: "student.submissions.message.info.practiceMode"}),
            type: 'info'
          }
          )
      );

    }

  };


  const handleSort = (columnKey) => {
    let direction;
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        direction = null; // Second click, reset to default (unsorted)
      } else if (sortConfig.direction === null) {
        direction = 'descending'; // Third click, sort descending
      } else {
        direction = 'ascending'; // First click, sort ascending
      }
    } else {
      direction = 'ascending'; // New column clicked, sort ascending
    }
    setSortConfig({ key: columnKey, direction });
  };



  const TableHeadCell = (column) => {
    if (column.dataIndex === 'id' || column.dataIndex === 'countSubmissions' || column.dataIndex === 'insight') {
      return column.title;
    }
    else {
      return (
          <>
            <span onClick={() => handleSort(column.dataIndex)} className={"sort_column"}>
              {()=> console.log('column.dataIndex', column.dataIndex)}
              <span>{column.title}</span>
              <span className={"sort__icons"}>
                <UpOutlined className={
                  (Array.isArray(sortConfig.key) && Array.isArray(column.dataIndex)
                      ? (sortConfig.direction === "ascending" && sortConfig.key.join() === column.dataIndex.join())
                      : (sortConfig.direction === "ascending" && sortConfig.key === column.dataIndex))
                      ? "up__icon active" : "up__icon"}/>
                <DownOutlined className={
                  (Array.isArray(sortConfig.key) && Array.isArray(column.dataIndex)
                      ? (sortConfig.direction === "descending" && sortConfig.key.join() === column.dataIndex.join())
                      : (sortConfig.direction === "descending" && sortConfig.key === column.dataIndex))
                      ? "down__icon active" : "down__icon"}/>
              </span>
            </span>
          </>
      );
    }
  };

  const TableBodyCell = (column, item) => {
    switch (column.dataIndex) {
      case 'countSubmissions':
        return (
            <Badge
                count={25}
                color={"#FFCF00"}
                className={"submissions-counter"}
                onClick={() => handleActionClick(item)}
            >
              <DocIcon /> Submissions
            </Badge>
        );
      case 'insight':
        return (
            <div
                className={"submissions-counter"}
                onClick={() => {}}
            >
              <InsightIcon /> Insights
            </div>
        );
      case 'type':
        if(column.render){
          return <span className='tableBody__cell-formatCol'>{column.render(item[column.dataIndex], item)} </span>;
        }
        else {
          return <span className='tableBody__cell-formatCol'>{ item[column.dataIndex] } </span>;
        }

      default:
        if(column.render){
         return column.render(item[column.dataIndex], item)
        }
        else {
            return item[column.dataIndex];
        }
    }
  };

if(isLoading){
    return <CustomSpin />
}
  return (
      <AppLayout hideHeader={true}>
        <div className="content__submissions submissions">
          <div className={"submission__top-page"}>
            <div className="submissions__filter">
              <span>
                {intl.formatMessage({id: "student.submissions.list.title"})}
              </span>
            </div>

            <div className="submissions-new">
              <Input
                  type="text"
                  placeholder={intl.formatMessage({id: "student.submissions.form.search"})}
                  prefix={<SearchOutlined />}
                  value={searchTerm}
                  onChange={handleSearchChange}
              />
            </div>
          </div>
          <Table
              columns={columns}
              data={dataSorted}
              TableHeadCell={TableHeadCell}
              TableBodyCell={TableBodyCell}
              noDataText={intl.formatMessage({id: "student.submissions.table.empty.data"})}
              searchTerm={searchTerm}
              isLoading={isLoading}
          />
        </div>
      </AppLayout>
  )
}

export default Submissions