
const ChatIcon = () => {

    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 12.9609H4.75L1.75 15.9609V10.7109" stroke="black" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M10 12.9609H10.75" stroke="black" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M10 2.46094H10.75" stroke="black" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M13.75 2.46094C14.1478 2.46094 14.5294 2.61897 14.8107 2.90028C15.092 3.18158 15.25 3.56311 15.25 3.96094"
                stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M15.25 10.7109V11.4609C15.25 11.8588 15.092 12.2403 14.8107 12.5216C14.5294 12.8029 14.1478 12.9609 13.75 12.9609"
                stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.25 6.96094V7.71094" stroke="black" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M1.75 6.96094V7.71094" stroke="black" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M3.25 2.46094C2.85218 2.46094 2.47064 2.61897 2.18934 2.90028C1.90804 3.18158 1.75 3.56311 1.75 3.96094"
                stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.25 2.46094H7" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default ChatIcon