import React, {useState} from "react";
import style from "../assignmentCorrectionsPreview/assignmentCorrectionsPreview.module.css";

const BarCorrectionCount = ({ length, correctLength }) => {
    const width = (correctLength / length) * 100;
    console.log(correctLength)
    const [currentActive, setCurrentActive] = useState(correctLength);

    const handleClick = (index) => {
        setCurrentActive(index );
    };


    return (
        <div className={style.barContainer}>

            {
                Array.from({ length }, (_, index) => (
                    <div
                        key={index}
                        className={`${style.scoreBar}  ${index <= currentActive && style.active} ` }
                        style={{ width: `${width}%` }}
                        onClick={() => { handleClick(index) }}
                    ></div>
                ))
            }
        </div>
    );
};

export default BarCorrectionCount;